// eslint-disable-next-line import/no-cycle
import { FundAttachmentType, type IndexFund } from './indexFundTypes';
import type { Logout } from './authTypes';
import { Product } from '../../types/product';

export const GET_RECOMMENDED_PORTFOLIO_REQUEST = 'GET_RECOMMENDED_PORTFOLIO_REQUEST';
export const GET_RECOMMENDED_PORTFOLIO_SUCCESS = 'GET_RECOMMENDED_PORTFOLIO_SUCCESS';
export const GET_RECOMMENDED_PORTFOLIO_FAIL = 'GET_RECOMMENDED_PORTFOLIO_FAIL';
export const GET_SUBSCRIBED_PORTFOLIO_REQUEST = 'GET_SUBSCRIBED_PORTFOLIO_REQUEST';
export const GET_SUBSCRIBED_PORTFOLIO_SUCCESS = 'GET_SUBSCRIBED_PORTFOLIO_SUCCESS';
export const GET_SUBSCRIBED_PORTFOLIO_FAIL = 'GET_SUBSCRIBED_PORTFOLIO_FAIL';
export const DO_SUBSCRIBE_TO_PORTFOLIO_REQUEST = 'DO_SUBSCRIBE_TO_PORTFOLIO_REQUEST';
export const DO_SUBSCRIBE_TO_PORTFOLIO_SUCCESS = 'DO_SUBSCRIBE_TO_PORTFOLIO_SUCCESS';
export const DO_SUBSCRIBE_TO_PORTFOLIO_FAIL = 'DO_SUBSCRIBE_TO_PORTFOLIO_FAIL';
export const DO_ADD_MORE_FUND_TO_PORTFOLIO_REQUEST = 'DO_ADD_MORE_FUND_TO_PORTFOLIO_REQUEST';
export const DO_ADD_MORE_FUND_TO_PORTFOLIO_SUCCESS = 'DO_ADD_MORE_FUND_TO_PORTFOLIO_SUCCESS';
export const DO_ADD_MORE_FUND_TO_PORTFOLIO_FAIL = 'DO_ADD_MORE_FUND_TO_PORTFOLIO_FAIL';
export const RESET_SUBSCRIBE_TO_PORTFOLIO = 'RESET_SUBSCRIBE_TO_PORTFOLIO';
export const GET_SUBSCRIPTIONS_HISTORY_REQUEST = 'GET_SUBSCRIPTIONS_HISTORY_REQUEST';
export const GET_SUBSCRIPTIONS_HISTORY_SUCCESS = 'GET_SUBSCRIPTIONS_HISTORY_SUCCESS';
export const GET_SUBSCRIPTIONS_HISTORY_FAIL = 'GET_SUBSCRIPTIONS_HISTORY_FAIL';
export const REDEEM_PORTFOLIO_REQUEST = 'REDEEM_PORTFOLIO_REQUEST';
export const REDEEM_PORTFOLIO_SUCCESS = 'REDEEM_PORTFOLIO_SUCCESS';
export const REDEEM_PORTFOLIO_FAIL = 'REDEEM_PORTFOLIO_FAIL';
export const CANCEL_SUBSCRIPTION_REQUEST = 'CANCEL_SUBSCRIPTION_REQUEST';
export const CANCEL_SUBSCRIPTION_SUCCESS = 'CANCEL_SUBSCRIPTION_SUCCESS';
export const CANCEL_SUBSCRIPTION_FAIL = 'CANCEL_SUBSCRIPTION_FAIL';
export const GET_PORTFOLIO_LATEST_STATISTICS_REQUEST = 'GET_PORTFOLIO_LATEST_STATISTICS_REQUEST';
export const GET_PORTFOLIO_LATEST_STATISTICS_SUCCESS = 'GET_PORTFOLIO_LATEST_STATISTICS_SUCCESS';
export const GET_PORTFOLIO_LATEST_STATISTICS_FAIL = 'GET_PORTFOLIO_LATEST_STATISTICS_FAIL';
export const GET_PORTFOLIO_STATISTICS_REQUEST = 'GET_PORTFOLIO_STATISTICS_REQUEST';
export const GET_PORTFOLIO_STATISTICS_SUCCESS = 'GET_PORTFOLIO_STATISTICS_SUCCESS';
export const GET_PORTFOLIO_STATISTICS_FAIL = 'GET_PORTFOLIO_STATISTICS_FAIL';
export const CLEAR_PORTFOLIO = 'CLEAR_PORTFOLIO';
export const RESET_REDEMPTION = 'RESET_REDEMPTION';
export const GET_AVAILABLE_PORTFOLIOS_REQUEST = 'GET_AVAILABLE_PORTFOLIOS_REQUEST';
export const GET_AVAILABLE_PORTFOLIOS_SUCCESS = 'GET_AVAILABLE_PORTFOLIOS_SUCCESS';
export const GET_AVAILABLE_PORTFOLIOS_FAIL = 'GET_AVAILABLE_PORTFOLIOS_FAIL';

export type AddMoreFundToPortfolioPayload = {
  portfolioId: number;
  fundAmount: number;
};

export type Asset = {
  id: number;
  nameEn: string;
  nameAr: string;
  descriptionEn: string;
  descriptionAr: string;
  createdAt: Date;
  updatedAt: Date;
};

export enum PortfolioFileType {
  FACT_SHEET = FundAttachmentType.FACT_SHEET,
  FACT_SHEET_AR = FundAttachmentType.FACT_SHEET_AR,
  DISCLOSURE = 'Disclosure',
  OTHER = 'Other',
}

export interface PortfolioFile {
  id: number;
  fileId: string;
  isPublic: boolean;
  portfolioFileType: { id: number; name: PortfolioFileType };
  createdAt: Date;
  updatedAt: Date;
}

export interface PortfolioAssociatedIndexFund {
  id: number;
  indexFund: IndexFund;
  indexFundId?: number;
  numOfUnits?: number;
  netAssetValuePerUnit?: number;
  weight: number;
  costBase?: number;
}

export type SubscribeToPortfolioPayload = {
  associatedIndexFunds: Pick<PortfolioAssociatedIndexFund, 'id' | 'weight'>[];
  portfolioId: number;
  fundAmount: number;
};

export type RISKS =
  | 'Low Risk Investor'
  | 'Conservative Investor'
  | 'Moderate Investor'
  | 'Growth Investor'
  | 'High Growth Investor';

export enum PortfoliotName {
  MADKHOL_BALANCED = 'Madkhol Balanced',
  MADKHOL_PASSIVE_PORTFOLIO = 'Madkhol Passive',
  CONVERSATIVE_PORTFOLIO = 'Conversative Portfolio',
}

export type UserCategory = {
  createdAt: string;
  descriptionAr: string;
  descriptionEn: string;
  id: number;
  nameAr: string;
  nameEn: RISKS;
  minScore: number;
  maxScore: number;
  updatedAt: Date;
};

type PortfolioBase = {
  id: number;
  nameEn: string;
  nameAr: string;
  descriptionEn: string;
  descriptionAr: string;
  objectiveEn: string;
  objectiveAr: string;
  shariaaComplaint: boolean;
  expectedMinimumReturn: number;
  targetExpectedReturn: number;
  expectedMaximumReturn: number;
  targetExpectedRisk: number;
  minimumFundAmount: number;
  benchmark: string;
  displayByGics: boolean;
  sharpRatio: number;
  productType: Product;
  files: PortfolioFile[];
  userCategory: UserCategory;
};

export type RecommendedPortfolio = PortfolioBase & {
  recommendedPortfolioId?: string;
  associatedIndexFunds: PortfolioAssociatedIndexFund[];
  createdBy: string;
  isLoading: boolean;
  isErrorExist: boolean;
  createdAt: Date | string;
  updatedAt: Date | string;
};

export enum SubscribedPortfolioStatus {
  PENDING = 'Pending',
  ACCEPTED = 'Accepted',
  REJECTED = 'Rejected',
}

export type SubscribeToPortfolio = {
  result: object;
  error: string;
  isLoading: boolean;
  isErrorExist: boolean;
};

export type AddMoreFundToPortfolio = {
  result: object;
  error: string;
  isLoading: boolean;
  isErrorExist: boolean;
};

export type SaveUserPrefrancePortfolio = {
  associatedIndexFunds: Pick<PortfolioAssociatedIndexFund, 'id' | 'weight'>[];
  portfolioId: number;
};

export enum SubscriptionStatus {
  PENDING = 'PENDING',
  IN_PROGRESS = 'IN_PROGRESS',
  APPROVED = 'APPROVED',
  WAITING_CAST_APPROVAL = 'WAITING_CAST_APPROVAL',
  APPROVED_BY_CAST = 'APPROVED_BY_CAST',
  CREATED = 'CREATED',
  CANCELED = 'CANCELED',
  REJECTED = 'REJECTED',
  FAILED = 'FAILED',
  PARTIAL_APPROVED_BY_CAST = 'PARTIAL_APPROVED_BY_CAST',
  PARTIAL_APPROVED = 'PARTIAL_APPROVED',
}

export interface UserPortfolio {
  id: number;
  userId: number;
  subscribedPortfolio: PortfolioBase;
  cacheAllocationPercentage: number;
  cacheAllocationValue: number;
  fundAmount: number;
  actualAmount: number;
  createdAt: Date;
  updatedAt: Date;
}

export enum SubscriptionType {
  SUBSCRIPTION = 'SUBSCRIPTION',
  REDEMPTION = 'REDEMPTION',
  UPGRADE = 'UPGRADE',
}

export type SubscriptionsHistoryDataItem = {
  id: number;
  changes: IAnyObject;
  createdAt: Date;
  updatedAt: Date;
  type: {
    id: number;
    name: SubscriptionType;
  };
  status: {
    id: number;
    name: SubscriptionStatus;
  };
  userPortfolio: UserPortfolio;
};

export interface UserPortfolioInSubscribedPortfolio extends UserPortfolio {
  userPortfolioRequests: Omit<SubscriptionsHistoryDataItem, 'userPortfolio'>[];
}

export type SubscribedPortfolio = PortfolioBase & {
  id: number;
  associatedIndexFunds: PortfolioAssociatedIndexFund[];
  userPortfolios: UserPortfolioInSubscribedPortfolio[];
  cacheAllocationValue?: number;
  nameEn: string;
  nameAr: string;
  descriptionAr: string;
  descriptionEn: string;
  status: {
    id: number;
    name: SubscribedPortfolioStatus;
  };
  isLoading: boolean;
  isErrorExist: boolean;
};

export type SubscriptionsHistoryData = {
  items: SubscriptionsHistoryDataItem[];
  totalItems: number;
};

export type SubscriptionsHistory = {
  data: SubscriptionsHistoryData;
  error: string;
  isLoading: boolean;
  isErrorExist: boolean;
};

export type AvailablePortfoliosData = {
  items: RecommendedPortfolio[];
  totalItems: number;
};

export type AvailablePortfolios = {
  data: AvailablePortfoliosData;
  error: string;
  isLoading: boolean;
  isErrorExist: boolean;
};

export type RedemptionPayload = {
  subscribedPortfolioId: number;
  amount?: number;
  reason: string;
  redeemAll: boolean;
};

export type Redemption = {
  error: string;
  isLoading: boolean;
  isErrorExist: boolean;
};

export type CancelSubscription = {
  error: string;
  isLoading: boolean;
  isErrorExist: boolean;
};

export interface AggregatedStatisticsIndexFundItem extends IAnyObject {
  costBase: number;
  numOfUnits: number;
  indexFundId: number;
  netAssetValuePerUnit: number;
}

export interface CalculatedTableItem {
  costBase: number;
  indexFundId: number;
  unitsStartOfDay: number;
  unitsEndOfDay: number;
  navStartOfDay: number;
  navEndOfDay: number;
  mvEndOfDay: number;
  mvStartOfDay: number;
  fundAddition: number;
  totalSarReturn: number;
  totalReturn: number;
}

export interface CalculatedTotalValues {
  mvStartOfDayTotal: number;
  mvEndOfDayTotal: number;
  totalCostBase: number;
  fundAdditionTotal: number;
}

export interface CalculatedDailyCompounding {
  indexFundId: number;
  totalReturn: number;
}

export interface CalculatedValues {
  calculationTable: CalculatedTableItem[];
  totalValues: CalculatedTotalValues;
  dailyCompounding: CalculatedDailyCompounding[];
  totalReturn: number;
}

export interface AggregatedStatisticsIndexFunds extends CalculatedValues {
  items: AggregatedStatisticsIndexFundItem[];
}

export type PortfolioStatisticsData = {
  id: number;
  userId: number;
  totalMarketValue: number;
  totalReturn: number;
  netDeposit: number;
  totalAmount: number;
  netWithdrawals: number;
  indexFunds?: AggregatedStatisticsIndexFunds;
  compoundingDailyReturn: number;
  prevCumulativeCompoundingDailyReturn: number;
  performanceReturn: number;
  date: string;
  createdAt: Date;
  updatedAt: Date;
};

export type PortfolioAllStatisticsItemData = Omit<PortfolioStatisticsData, 'indexFunds' | 'createdAt' | 'updatedAt'>;

export type PortfolioStatistics = {
  latest: PortfolioStatisticsData | null;
  all: PortfolioAllStatisticsItemData[];
  error: string;
  isLoading: boolean;
  isErrorExist: boolean;
};

export type FindSubscriptionsHistoryPayload = PaginatedRequest;

export interface RequestGetRecommendedPortfolio {
  type: typeof GET_RECOMMENDED_PORTFOLIO_REQUEST;
}

export interface RequestGetRecommendedPortfolioSuccess {
  type: typeof GET_RECOMMENDED_PORTFOLIO_SUCCESS;
  payload: RecommendedPortfolio;
}

export interface RequestGetRecommendedPortfolioFail {
  type: typeof GET_RECOMMENDED_PORTFOLIO_FAIL;
}

export interface RequestGetSubscribedPortfolio {
  type: typeof GET_SUBSCRIBED_PORTFOLIO_REQUEST;
}

export interface RequestGetSubscribedPortfolioSuccess {
  type: typeof GET_SUBSCRIBED_PORTFOLIO_SUCCESS;
  payload: { items: SubscribedPortfolio[]; totalItems: number };
}

export interface RequestGetSubscribedPortfolioFail {
  type: typeof GET_SUBSCRIBED_PORTFOLIO_FAIL;
}

export interface RequestSubscribeToPortfolio {
  type: typeof DO_SUBSCRIBE_TO_PORTFOLIO_REQUEST;
}

export interface RequestSubscribeToPortfolioSuccess {
  type: typeof DO_SUBSCRIBE_TO_PORTFOLIO_SUCCESS;
  payload: SubscribedPortfolio;
}

export interface RequestSubscribeToPortfolioFail {
  type: typeof DO_SUBSCRIBE_TO_PORTFOLIO_FAIL;
  payload: string;
}

export interface RequestAddMoreFundToPortfolio {
  type: typeof DO_ADD_MORE_FUND_TO_PORTFOLIO_REQUEST;
}

export interface RequestAddMoreFundToPortfolioSuccess {
  type: typeof DO_ADD_MORE_FUND_TO_PORTFOLIO_SUCCESS;
  payload: SubscribedPortfolio;
}

export interface RequestAddMoreFundToPortfolioFail {
  type: typeof DO_ADD_MORE_FUND_TO_PORTFOLIO_FAIL;
  payload: string;
}

export interface ResetSubscribedToPortfolio {
  type: typeof RESET_SUBSCRIBE_TO_PORTFOLIO;
}

export interface RequestSubscriptionsHistoryPortfolio {
  type: typeof GET_SUBSCRIPTIONS_HISTORY_REQUEST;
}

export interface RequestSubscriptionsHistorySuccess {
  type: typeof GET_SUBSCRIPTIONS_HISTORY_SUCCESS;
  payload: { result: SubscriptionsHistoryData; isLoadMore: boolean };
}

export interface RequestGetSubscriptionsHistoryFail {
  type: typeof GET_SUBSCRIPTIONS_HISTORY_FAIL;
  payload: string;
}

export interface RequestRedeemPortfolio {
  type: typeof REDEEM_PORTFOLIO_REQUEST;
}

export interface RequestRedeemPortfolioSuccess {
  type: typeof REDEEM_PORTFOLIO_SUCCESS;
}

export interface RequestRedeemPortfolioFail {
  type: typeof REDEEM_PORTFOLIO_FAIL;
  payload: string;
}

export interface RequestCancelSubscription {
  type: typeof CANCEL_SUBSCRIPTION_REQUEST;
}

export interface RequestCancelSubscriptionSuccess {
  type: typeof CANCEL_SUBSCRIPTION_SUCCESS;
}

export interface RequestCancelSubscriptionFail {
  type: typeof CANCEL_SUBSCRIPTION_FAIL;
  payload: string;
}

export interface RequestGetAvailablePortfolios {
  type: typeof GET_AVAILABLE_PORTFOLIOS_REQUEST;
}

export interface RequestGetAvailablePortfoliosSuccess {
  type: typeof GET_AVAILABLE_PORTFOLIOS_SUCCESS;
  payload: {
    totalItems: number;
    items: RecommendedPortfolio[];
  };
}

export interface RequestGetAvailablePortfoliosFail {
  type: typeof GET_AVAILABLE_PORTFOLIOS_FAIL;
  payload: string;
}

export interface RequestPortfolioLatestStatistics {
  type: typeof GET_PORTFOLIO_LATEST_STATISTICS_REQUEST;
}

export interface RequestPortfolioLatestStatisticsSuccess {
  type: typeof GET_PORTFOLIO_LATEST_STATISTICS_SUCCESS;
  payload: PortfolioStatisticsData;
}

export interface RequestGetPortfolioLatestStatisticsFail {
  type: typeof GET_PORTFOLIO_LATEST_STATISTICS_FAIL;
  payload: string;
}

export interface RequestPortfolioStatistics {
  type: typeof GET_PORTFOLIO_STATISTICS_REQUEST;
}

export interface RequestPortfolioStatisticsSuccess {
  type: typeof GET_PORTFOLIO_STATISTICS_SUCCESS;
  payload: PortfolioAllStatisticsItemData[];
}

export interface RequestGetPortfolioStatisticsFail {
  type: typeof GET_PORTFOLIO_STATISTICS_FAIL;
  payload: string;
}

export interface ClearPortfolio {
  type: typeof CLEAR_PORTFOLIO;
}

export interface ResetRedemption {
  type: typeof RESET_REDEMPTION;
}

export type PortfolioAction =
  | RequestGetRecommendedPortfolio
  | RequestGetRecommendedPortfolioSuccess
  | RequestGetRecommendedPortfolioFail
  | RequestGetSubscribedPortfolio
  | RequestGetSubscribedPortfolioSuccess
  | RequestGetSubscribedPortfolioFail
  | RequestSubscribeToPortfolio
  | RequestSubscribeToPortfolioSuccess
  | RequestSubscribeToPortfolioFail
  | RequestAddMoreFundToPortfolio
  | RequestAddMoreFundToPortfolioSuccess
  | RequestAddMoreFundToPortfolioFail
  | RequestSubscriptionsHistoryPortfolio
  | RequestSubscriptionsHistorySuccess
  | RequestGetSubscriptionsHistoryFail
  | RequestRedeemPortfolio
  | RequestRedeemPortfolioSuccess
  | RequestRedeemPortfolioFail
  | RequestCancelSubscription
  | RequestCancelSubscriptionSuccess
  | RequestCancelSubscriptionFail
  | RequestGetAvailablePortfolios
  | RequestGetAvailablePortfoliosSuccess
  | RequestGetAvailablePortfoliosFail
  | RequestPortfolioLatestStatistics
  | RequestPortfolioLatestStatisticsSuccess
  | RequestGetPortfolioLatestStatisticsFail
  | RequestPortfolioStatistics
  | RequestPortfolioStatisticsSuccess
  | RequestGetPortfolioStatisticsFail
  | ResetSubscribedToPortfolio
  | Logout
  | ClearPortfolio
  | ResetRedemption;

export type PortfolioState = Readonly<{
  recommendedPortfolio: RecommendedPortfolio;
  subscribedPortfolio: SubscribedPortfolio;
  subscribedPortfolios: SubscribedPortfolio[];
  subscribeToPortfolio: SubscribeToPortfolio;
  addMoreFundToPortfolio: AddMoreFundToPortfolio;
  subscriptionsHistory: SubscriptionsHistory;
  redemption: Redemption;
  cancelSubscription: CancelSubscription;
  statistics: PortfolioStatistics;
  availablePortfolios: AvailablePortfolios;
}>;

export interface AssociatedIndexFund {
  id: number;
  subscribedPortfolioID: number;
  indexFundID: number;
  indexFund: IndexFund;
  weight: number;
  costBase: number;
  createdAt: Date;
  updatedAt: Date;
  numOfUnits: number;
  netAssetValuePerUnit: number;
  nameEn: string;
  nameAr: string;
}

export interface Benchmark {
  id: number;
  nameEn: string;
  nameAr: string;
  createdAt: Date;
  updatedAt: Date;
}

export interface Currency {
  id: number;
  name: string;
  code: string;
  isDeleted: boolean;
  createdAt: Date;
  updatedAt: Date;
}

export interface ProductType {
  id: number;
  nameEn: string;
  nameAr: string;
  descriptionEn: string;
  descriptionAr: string;
  minimumPortfolioInvestment: number;
  minimumAddFundInvestment: number;
  minimumRedemption: number;
  fee: number;
  scoreDependent: boolean;
  isCustom: boolean;
  disabled: boolean;
  createdAt: Date;
  updatedAt: Date;
}

export interface Status {
  id: number;
  name: string;
}

export interface NewProduct {
  id: number;
  userID: number;
  nameEn: string;
  nameAr: string;
  descriptionEn: string;
  descriptionAr: string;
  objectiveEn: string;
  objectiveAr: string;
  shariaaComplaint: boolean;
  minimumFundAmount: number;
  expectedMinimumReturn: number;
  targetExpectedReturn: number;
  expectedMaximumReturn: number;
  targetExpectedRisk: number;
  displayByGics: boolean;
  sharpRatio: number;
  createdAt: Date;
  updatedAt: Date;
  generatedStatisticsAt: Date;
  archivedAt: Date;
  subscribedPortfolioAssociatedIndexFund: AssociatedIndexFund[];
  productType: ProductType;
  status: Status;
  currency: Currency;
  benchmark: Benchmark;
  manager: null;
  cacheAllocationValue: number;
  associatedIndexFunds: AssociatedIndexFund[];
}

export type FetchProductItemsResponse = {
  items: NewProduct[];
  totalItems: number;
};
