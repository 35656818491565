import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

export interface VideoModalProps {
  open: boolean;
  title: string;
  videoUrl: string;
  handleClose: (e: any) => void;
}

const VideoModal: React.FC<VideoModalProps> = ({ open, title, videoUrl, handleClose }) => (
  <Dialog onClose={handleClose} open={open} maxWidth="lg" fullWidth disableScrollLock>
    <DialogTitle sx={{ m: 0, p: 2 }}>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
        }}
      >
        <CloseIcon />
      </IconButton>
    </DialogTitle>
    <DialogContent
      sx={{
        padding: 8,
        marginTop: 8,
        height: { xs: '50vh', sm: '70vh' },
        overflow: 'hidden',
      }}
    >
      <iframe
        width="100%"
        height="100%"
        src={videoUrl}
        title={title}
        style={{ borderRadius: '8px' }}
        frameBorder="0"
        allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture;"
      />
    </DialogContent>
  </Dialog>
);

export default VideoModal;
