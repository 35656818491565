import { AnyAction } from 'redux';
import { ThunkAction } from 'redux-thunk';
import {
  cancelSubscriptionRequest,
  doAddMoreFundToPortfolio,
  doSubscribeToPortfolio,
  getAvailablePortfoliosRequest,
  getPortfolioByID,
  getPortfolioLatestStatistics,
  getPortfolioStatistics,
  getRecommendedPortfolio,
  getRecommendedPortfolioByProductId,
  getSubscribedPortfolio,
  getSubscriptionsHistory,
  redeemPortfolioRequest,
} from '../../helpers/api_deprecated';
import {
  AddMoreFundToPortfolioPayload,
  AvailablePortfoliosData,
  CANCEL_SUBSCRIPTION_FAIL,
  CANCEL_SUBSCRIPTION_REQUEST,
  CANCEL_SUBSCRIPTION_SUCCESS,
  CLEAR_PORTFOLIO,
  ClearPortfolio,
  DO_ADD_MORE_FUND_TO_PORTFOLIO_FAIL,
  DO_ADD_MORE_FUND_TO_PORTFOLIO_REQUEST,
  DO_ADD_MORE_FUND_TO_PORTFOLIO_SUCCESS,
  DO_SUBSCRIBE_TO_PORTFOLIO_FAIL,
  DO_SUBSCRIBE_TO_PORTFOLIO_REQUEST,
  DO_SUBSCRIBE_TO_PORTFOLIO_SUCCESS,
  FindSubscriptionsHistoryPayload,
  GET_AVAILABLE_PORTFOLIOS_FAIL,
  GET_AVAILABLE_PORTFOLIOS_REQUEST,
  GET_AVAILABLE_PORTFOLIOS_SUCCESS,
  GET_PORTFOLIO_LATEST_STATISTICS_FAIL,
  GET_PORTFOLIO_LATEST_STATISTICS_REQUEST,
  GET_PORTFOLIO_LATEST_STATISTICS_SUCCESS,
  GET_PORTFOLIO_STATISTICS_FAIL,
  GET_PORTFOLIO_STATISTICS_REQUEST,
  GET_PORTFOLIO_STATISTICS_SUCCESS,
  GET_RECOMMENDED_PORTFOLIO_FAIL,
  GET_RECOMMENDED_PORTFOLIO_REQUEST,
  GET_RECOMMENDED_PORTFOLIO_SUCCESS,
  GET_SUBSCRIBED_PORTFOLIO_FAIL,
  GET_SUBSCRIBED_PORTFOLIO_REQUEST,
  GET_SUBSCRIBED_PORTFOLIO_SUCCESS,
  GET_SUBSCRIPTIONS_HISTORY_FAIL,
  GET_SUBSCRIPTIONS_HISTORY_REQUEST,
  GET_SUBSCRIPTIONS_HISTORY_SUCCESS,
  PortfolioAllStatisticsItemData,
  PortfolioStatisticsData,
  RecommendedPortfolio,
  REDEEM_PORTFOLIO_FAIL,
  REDEEM_PORTFOLIO_REQUEST,
  REDEEM_PORTFOLIO_SUCCESS,
  RedemptionPayload,
  RequestAddMoreFundToPortfolio,
  RequestAddMoreFundToPortfolioFail,
  RequestAddMoreFundToPortfolioSuccess,
  RequestCancelSubscription,
  RequestCancelSubscriptionFail,
  RequestCancelSubscriptionSuccess,
  RequestGetAvailablePortfolios,
  RequestGetAvailablePortfoliosFail,
  RequestGetAvailablePortfoliosSuccess,
  RequestGetPortfolioLatestStatisticsFail,
  RequestGetPortfolioStatisticsFail,
  RequestGetRecommendedPortfolio,
  RequestGetRecommendedPortfolioFail,
  RequestGetRecommendedPortfolioSuccess,
  RequestGetSubscribedPortfolio,
  RequestGetSubscribedPortfolioFail,
  RequestGetSubscribedPortfolioSuccess,
  RequestGetSubscriptionsHistoryFail,
  RequestPortfolioLatestStatistics,
  RequestPortfolioLatestStatisticsSuccess,
  RequestPortfolioStatistics,
  RequestPortfolioStatisticsSuccess,
  RequestRedeemPortfolio,
  RequestRedeemPortfolioFail,
  RequestRedeemPortfolioSuccess,
  RequestSubscribeToPortfolio,
  RequestSubscribeToPortfolioFail,
  RequestSubscribeToPortfolioSuccess,
  RequestSubscriptionsHistoryPortfolio,
  RequestSubscriptionsHistorySuccess,
  RESET_REDEMPTION,
  RESET_SUBSCRIBE_TO_PORTFOLIO,
  ResetSubscribedToPortfolio,
  SubscribedPortfolio,
  SubscribeToPortfolioPayload,
  SubscriptionsHistoryData,
} from '../types/portfolioTypes';

export const requestGetRecommendedPortfolio = (): RequestGetRecommendedPortfolio => ({
  type: GET_RECOMMENDED_PORTFOLIO_REQUEST,
});

export const requestGetRecommendedPortfolioSuccess = (
  result: RecommendedPortfolio,
): RequestGetRecommendedPortfolioSuccess => ({
  type: GET_RECOMMENDED_PORTFOLIO_SUCCESS,
  payload: result,
});

export const requestGetRecommendedPortfolioFail = (): RequestGetRecommendedPortfolioFail => ({
  type: GET_RECOMMENDED_PORTFOLIO_FAIL,
});

export const requestGetSubscribedPortfolio = (): RequestGetSubscribedPortfolio => ({
  type: GET_SUBSCRIBED_PORTFOLIO_REQUEST,
});

export const requestGetSubscribedPortfolioSuccess = (result: {
  items: SubscribedPortfolio[];
  totalItems: number;
}): RequestGetSubscribedPortfolioSuccess => ({
  type: GET_SUBSCRIBED_PORTFOLIO_SUCCESS,
  payload: result,
});

export const requestGetSubscribedPortfolioFail = (): RequestGetSubscribedPortfolioFail => ({
  type: GET_SUBSCRIBED_PORTFOLIO_FAIL,
});

export const requestSubscribeToPortfolio = (): RequestSubscribeToPortfolio => ({
  type: DO_SUBSCRIBE_TO_PORTFOLIO_REQUEST,
});

export const requestSubscribeToPortfolioSuccess = (
  result: SubscribedPortfolio,
): RequestSubscribeToPortfolioSuccess => ({
  type: DO_SUBSCRIBE_TO_PORTFOLIO_SUCCESS,
  payload: result,
});

export const requestSubscribeToPortfolioFail = (payload: string): RequestSubscribeToPortfolioFail => ({
  type: DO_SUBSCRIBE_TO_PORTFOLIO_FAIL,
  payload,
});

export const requestAddMoreFundToPortfolio = (): RequestAddMoreFundToPortfolio => ({
  type: DO_ADD_MORE_FUND_TO_PORTFOLIO_REQUEST,
});

export const requestAddMoreFundToPortfolioSuccess = (
  result: SubscribedPortfolio,
): RequestAddMoreFundToPortfolioSuccess => ({
  type: DO_ADD_MORE_FUND_TO_PORTFOLIO_SUCCESS,
  payload: result,
});

export const requestAddMoreFundToPortfolioFail = (payload: string): RequestAddMoreFundToPortfolioFail => ({
  type: DO_ADD_MORE_FUND_TO_PORTFOLIO_FAIL,
  payload,
});

export const resetSubscribedToPortfolio = (): ResetSubscribedToPortfolio => ({
  type: RESET_SUBSCRIBE_TO_PORTFOLIO,
});

export const requestSubscriptionsHistoryPortfolio = (): RequestSubscriptionsHistoryPortfolio => ({
  type: GET_SUBSCRIPTIONS_HISTORY_REQUEST,
});

export const requestSubscriptionsHistorySuccess = (payload: {
  result: SubscriptionsHistoryData;
  isLoadMore: boolean;
}): RequestSubscriptionsHistorySuccess => ({
  type: GET_SUBSCRIPTIONS_HISTORY_SUCCESS,
  payload,
});

export const requestGetSubscriptionsHistoryFail = (payload: string): RequestGetSubscriptionsHistoryFail => ({
  type: GET_SUBSCRIPTIONS_HISTORY_FAIL,
  payload,
});

export const requestPortfolioLatestStatistics = (): RequestPortfolioLatestStatistics => ({
  type: GET_PORTFOLIO_LATEST_STATISTICS_REQUEST,
});

export const requestPortfolioLatestStatisticsSuccess = (
  result: PortfolioStatisticsData,
): RequestPortfolioLatestStatisticsSuccess => ({
  type: GET_PORTFOLIO_LATEST_STATISTICS_SUCCESS,
  payload: result,
});

export const requestGetPortfolioLatestStatisticsFail = (payload: string): RequestGetPortfolioLatestStatisticsFail => ({
  type: GET_PORTFOLIO_LATEST_STATISTICS_FAIL,
  payload,
});

export const requestPortfolioStatistics = (): RequestPortfolioStatistics => ({
  type: GET_PORTFOLIO_STATISTICS_REQUEST,
});

export const requestPortfolioStatisticsSuccess = (
  result: PortfolioAllStatisticsItemData[],
): RequestPortfolioStatisticsSuccess => ({
  type: GET_PORTFOLIO_STATISTICS_SUCCESS,
  payload: result,
});

export const requestGetPortfolioStatisticsFail = (payload: string): RequestGetPortfolioStatisticsFail => ({
  type: GET_PORTFOLIO_STATISTICS_FAIL,
  payload,
});

export const requestRedeemPortfolio = (): RequestRedeemPortfolio => ({
  type: REDEEM_PORTFOLIO_REQUEST,
});

export const requestRedeemPortfolioSuccess = (): RequestRedeemPortfolioSuccess => ({
  type: REDEEM_PORTFOLIO_SUCCESS,
});

export const requestRedeemPortfolioFail = (payload: string): RequestRedeemPortfolioFail => ({
  type: REDEEM_PORTFOLIO_FAIL,
  payload,
});

export const requestCancelSubscription = (): RequestCancelSubscription => ({
  type: CANCEL_SUBSCRIPTION_REQUEST,
});

export const requestCancelSubscriptionSuccess = (): RequestCancelSubscriptionSuccess => ({
  type: CANCEL_SUBSCRIPTION_SUCCESS,
});

export const requestCancelSubscriptionFail = (payload: string): RequestCancelSubscriptionFail => ({
  type: CANCEL_SUBSCRIPTION_FAIL,
  payload,
});

export const requestGetAvailablePortfolios = (): RequestGetAvailablePortfolios => ({
  type: GET_AVAILABLE_PORTFOLIOS_REQUEST,
});

export const requestGetAvailablePortfoliosSuccess = (
  payload: AvailablePortfoliosData,
): RequestGetAvailablePortfoliosSuccess => ({
  type: GET_AVAILABLE_PORTFOLIOS_SUCCESS,
  payload,
});

export const requestGetAvailablePortfoliosFail = (payload: string): RequestGetAvailablePortfoliosFail => ({
  type: GET_AVAILABLE_PORTFOLIOS_FAIL,
  payload,
});

export const clearPortfolio = (): ClearPortfolio => ({
  type: CLEAR_PORTFOLIO,
});

export const resetRedemption = (): AnyAction => ({
  type: RESET_REDEMPTION,
});

export const subscribedToPortfolio =
  (payload: SubscribeToPortfolioPayload): ThunkAction<any, any, unknown, AnyAction> =>
  async (dispatch) => {
    dispatch(requestSubscribeToPortfolio());
    try {
      const response: { data: SubscribedPortfolio } = await doSubscribeToPortfolio(payload);

      dispatch(requestSubscribeToPortfolioSuccess(response.data));

      return response.data;
    } catch (error: any) {
      dispatch(requestSubscribeToPortfolioFail(error.message));
    }
  };

export const addMoreFundToPortfolio =
  (payload: AddMoreFundToPortfolioPayload): ThunkAction<any, any, unknown, AnyAction> =>
  async (dispatch) => {
    dispatch(requestAddMoreFundToPortfolio());
    try {
      const response: { data: SubscribedPortfolio } = await doAddMoreFundToPortfolio(payload);

      dispatch(requestAddMoreFundToPortfolioSuccess(response.data));

      return response.data;
    } catch (error: any) {
      dispatch(requestAddMoreFundToPortfolioFail(error.message));
    }
  };

export const findRecommendedPortfolio =
  (payload: string): ThunkAction<any, any, unknown, AnyAction> =>
  async (dispatch) => {
    dispatch(requestGetRecommendedPortfolio());
    try {
      const response: any = await getRecommendedPortfolio(payload);

      dispatch(requestGetRecommendedPortfolioSuccess(response.data));
      // return result;
    } catch (error) {
      dispatch(requestGetRecommendedPortfolioFail());
    }
  };

export const findRecommendedPortfolioByProduct =
  (payload: { productTypeId: number; category?: string }): ThunkAction<any, any, unknown, AnyAction> =>
  async (dispatch) => {
    dispatch(requestGetRecommendedPortfolio());
    try {
      const response: any = await getRecommendedPortfolioByProductId(payload.productTypeId, payload.category);

      dispatch(requestGetRecommendedPortfolioSuccess(response.data));
      // return result;
    } catch (error) {
      dispatch(requestGetRecommendedPortfolioFail());
    }
  };

export const findSubscribedPortfolio = (): ThunkAction<any, any, unknown, AnyAction> => async (dispatch) => {
  dispatch(requestGetSubscribedPortfolio());
  try {
    const response: any = await getSubscribedPortfolio();

    dispatch(requestGetSubscribedPortfolioSuccess(response.data));

    return response.data;
  } catch (error) {
    dispatch(requestGetSubscribedPortfolioFail());
  }
};

export const findSubscriptionsHistory =
  (payload: FindSubscriptionsHistoryPayload): ThunkAction<any, any, unknown, AnyAction> =>
  async (dispatch) => {
    dispatch(requestSubscriptionsHistoryPortfolio());
    try {
      const isLoadMore = !!payload.offset && payload.offset > 0;

      const response: any = await getSubscriptionsHistory(payload);

      dispatch(requestSubscriptionsHistorySuccess({ result: response.data, isLoadMore }));

      return response.data;
    } catch (error: any) {
      dispatch(requestGetSubscriptionsHistoryFail(error.message));
    }
  };

export const findPortfolioLatestStatistics = (): ThunkAction<any, any, unknown, AnyAction> => async (dispatch) => {
  dispatch(requestPortfolioLatestStatistics());
  try {
    const response: any = await getPortfolioLatestStatistics();

    dispatch(requestPortfolioLatestStatisticsSuccess(response.data));

    return response.data;
  } catch (error: any) {
    dispatch(requestGetPortfolioLatestStatisticsFail(error.message));
  }
};

export const findPortfolioStatistics = (): ThunkAction<any, any, unknown, AnyAction> => async (dispatch) => {
  dispatch(requestPortfolioStatistics());
  try {
    const response: any = await getPortfolioStatistics();

    dispatch(requestPortfolioStatisticsSuccess(response.data));

    return response.data;
  } catch (error: any) {
    dispatch(requestGetPortfolioStatisticsFail(error.message));
  }
};

export const redeemPortfolio =
  (payload: RedemptionPayload): ThunkAction<any, any, unknown, AnyAction> =>
  async (dispatch) => {
    dispatch(requestRedeemPortfolio());
    try {
      const response: any = await redeemPortfolioRequest(payload);

      dispatch(requestRedeemPortfolioSuccess());

      return response.data;
    } catch (error: any) {
      dispatch(requestRedeemPortfolioFail(error.message));
    }
  };

export const cancelSubscription =
  (id: number): ThunkAction<any, any, unknown, AnyAction> =>
  async (dispatch) => {
    dispatch(requestCancelSubscription());
    try {
      const response: any = await cancelSubscriptionRequest(id);

      dispatch(requestCancelSubscriptionSuccess());

      return response.data;
    } catch (error: any) {
      dispatch(requestCancelSubscriptionFail(error.message));
    }
  };

export const fetchAvailablePortfolios = (): ThunkAction<any, any, unknown, AnyAction> => async (dispatch) => {
  dispatch(requestGetAvailablePortfolios());
  try {
    const { data }: any = await getAvailablePortfoliosRequest();
    const items = await Promise.all(data.items.map(({ id }: any) => getPortfolioByID(id)));

    const result = {
      totalItems: data.totalItems,
      items: items.map(({ data: item }) => ({
        ...item,
        userCategory: item.userCategory[0].userCategory,
      })),
    };

    dispatch(requestGetAvailablePortfoliosSuccess(result));

    return result;
  } catch (error: any) {
    dispatch(requestGetAvailablePortfoliosFail(error.message));
  }
};
