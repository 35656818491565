/* eslint-disable react/jsx-no-useless-fragment */
// @ts-nocheck
import React, { useState, useEffect } from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Alert, Box, Snackbar, useMediaQuery } from '@mui/material';
import Settings from '@mui/icons-material/Settings';
import Tooltip from '@mui/material/Tooltip';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { useTypedDispatch } from '../../../redux/store';
import COLORS from '../../../constants/colors';

import {
  deleteSubscription,
  fetchSubscriptions,
  setPrimarySubscriptionCard,
} from '../../../store_deprecated/actions/walletActions';
import { subscriptionsIsLoading, subscriptionsSelector } from '../../../store_deprecated/selectors/walletSelectors';
import { SubscriptionItem } from '../../../store_deprecated/types/walletTypes';
import { RecurringStatus } from '../../../forms/fields/recurringPayment';
import RecurrentBillingForm from './RecurrentBillingForm';

export type CardItem = {
  id: number;
  amount: number;
  startDate: Date | string;
  endDate: Date | string | null;
  endDateType: string;
  frequencyUnit: string;
  paymentBrand: string;
  last4Digits: string;
  status: string;
  isPrimaryCard: boolean;
  isDeleted: boolean;
  deletedAt: Date | string | null;
};

const NewCards: React.FC = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useTypedDispatch();
  const cards = useSelector(subscriptionsSelector);
  const [open, setOpen] = useState(false);
  const isLoadingSubscriptions = useSelector(subscriptionsIsLoading);
  const isScreenSM = useMediaQuery('(min-width: 600px)');

  const [openRecurringForm, setOpenRecurringForm] = useState<{ [key: number]: boolean } | null>(
    cards.length ? cards.reduce((acc, cardItem) => ({ ...acc, [cardItem.id]: false }), {}) : null,
  );

  const [selectedCard, setSelectedCard] = useState<SubscriptionItem | null>(() => {
    const primaryCard = cards.find((card) => card.isPrimaryCard);
    return primaryCard || (cards.length ? cards[0] : null);
  });
  const [subscriptions, setSubscriptions] = useState<SubscriptionItem | null>([]);
  const [windowsLocations, setWindowsLocations] = useState<number[]>([]);
  const [windowSize, setWindowSize] = useState<number>(0);
  const [tooltipOpenIndex, setTooltipOpenIndex] = useState(null);
  const [isRecurringChanged, setIsRecurringChanged] = useState(false);

  const hundredPercent = useMediaQuery<Theme>((theme) => theme.breakpoints.down('sm'));
  const fiftyPercent = useMediaQuery<Theme>((theme) => theme.breakpoints.between('sm', 'md'));
  const thirtyPercent = useMediaQuery<Theme>((theme) => theme.breakpoints.up('md'));

  const isEnglish = i18n.language === 'en';

  const onCloseRecurringSettings = (id: number) => {
    setOpenRecurringForm((prev) => ({ ...prev, [id]: prev ? !prev[id] : false }));
    setSelectedCard(null);
    setTooltipOpenIndex(null);
  };

  const onSetPrimaryCard = async (e: React.MouseEvent<HTMLElement>, id: number) => {
    e.stopPropagation();
    const result: boolean = (await dispatch(setPrimarySubscriptionCard(id))) as any;
    if (result) {
      const updatedCards = cards.map((card) => ({
        ...card,
        isPrimaryCard: card.id === id,
      }));
      setSelectedCard(updatedCards.find((card) => card.id === id) || null);
      dispatch(fetchSubscriptions());
    }
  };

  const onDeleteSubscription = async (e: React.MouseEvent<HTMLElement>, id: number) => {
    e.stopPropagation();
    const updatedCards = cards.filter((card) => card.id !== id);
    setSubscriptions(updatedCards);
    const result: boolean = await dispatch(deleteSubscription(id));
    if (!result) {
      dispatch(fetchSubscriptions());
    }
  };
  const onSelectCard = (e: React.MouseEvent<HTMLElement>, card: SubscriptionItem) => {
    e.stopPropagation();
    if (selectedCard?.id !== card.id) {
      setSelectedCard(card);
      setOpenRecurringForm((prev) => ({ ...prev, [card.id]: selectedCard?.id !== card.id }));
    }
  };

  const getWindowIndex = (id) => {
    const itemIndex = cards.findIndex((card) => card.id === id);

    return Math.floor(itemIndex / windowSize);
  };

  useEffect(() => {
    if (cards && cards.length > 0) {
      const primaryCardExists = cards.find((card) => card.isPrimaryCard);
      if (!primaryCardExists) {
        cards[0].isPrimaryCard = true;
      }
      setSelectedCard(cards.find((card) => card.isPrimaryCard) || cards[0]);
    }
  }, [cards]);

  useEffect(() => {
    if (hundredPercent) setWindowSize(1);
    else if (fiftyPercent) setWindowSize(2);
    else if (thirtyPercent) setWindowSize(3);
  }, []);

  useEffect(() => {
    if (cards.length) {
      const newCards = [];
      const tempWindowsLocations = [];

      Array(Math.ceil(cards.length / windowSize))
        .fill()
        // eslint-disable-next-line array-callback-return
        .map((_item, i) => {
          const windowData = cards.slice(i * windowSize, i * windowSize + windowSize);
          if (windowData.length !== windowData) {
            const fakeObjectsToFillWindow = new Array(windowSize - windowData.length).fill({});
            windowData.push(...fakeObjectsToFillWindow);
          }

          tempWindowsLocations.push(newCards.flat().length + windowData.length);

          newCards.push([...windowData, { isFormRow: true, showForIds: windowData.map((item) => item?.id) }]);
        });
      setWindowsLocations(tempWindowsLocations);
      setSubscriptions(newCards.flat());
      const defaultSelectedCard = cards.find((card) => card.isPrimaryCard) || cards[0];
      setSelectedCard(defaultSelectedCard);
      if (!openRecurringForm) {
        setOpenRecurringForm({ [defaultSelectedCard.id]: true });
      }
    } else {
      // If cards.length is zero then subscription should set to empty
      setSubscriptions([]);
    }
  }, [cards]);

  useEffect(() => {
    setOpen(false);
  }, [selectedCard]);

  const handleTooltipToggle = (index) => {
    if (tooltipOpenIndex === index) {
      setTooltipOpenIndex(null);
    } else {
      setTooltipOpenIndex(index);
    }
  };

  const handleTooltipClose = () => {
    setTooltipOpenIndex(null);
  };

  const onCloseUpdatedRecurringNotification = () => {
    setIsRecurringChanged(false);
  };

  const onOpenUpdatedRecurringNotification = () => {
    setIsRecurringChanged(true);
  };

  const renderContent = () => (
    <>
      {subscriptions.length ? (
        <Grid container width="100%" sx={{ gap: '24px', mt: '24px', justifyContent: 'flex-start' }}>
          {subscriptions.map((cardItem) => {
            if (!cardItem?.id) return null;
            return (
              <Box
                key={cardItem.id}
                sx={{
                  cursor: 'pointer',
                  my: 'auto',
                  width: {
                    xs: '100%',
                    sm: 'calc(50% - 12px)',
                    md: 'calc(50% - 12px)',
                    lg: 'calc(33% - 12px)',
                    xl: 'calc(33% - 12px)',
                  },
                }}
              >
                {/* credit Crad */}
                <Grid
                  item
                  onClick={(e) => {
                    onSelectCard(e, cardItem);
                  }}
                  sx={{
                    px: { xs: '16px', sm: '24px' },
                    py: { xs: '16px', sm: '28px' },
                    borderRadius: '12px',
                    backgroundColor: selectedCard?.id === cardItem.id ? COLORS.X_DARK_BLUE : COLORS.WHITE,
                    height: 'fit-content',
                    gap: { xs: '24px', md: '32px' },
                    display: 'grid',
                  }}
                >
                  <Grid container flexDirection="row" alignItems="center" justifyContent="space-between">
                    <Grid item sx={{ display: 'flex', alignItems: 'center' }}>
                      <img
                        key={cardItem.id}
                        width={40}
                        src={
                          selectedCard?.id === cardItem.id
                            ? '/images/card-brand/card-selected.png'
                            : '/images/card-brand/card-unselected.png'
                        }
                        alt="card"
                      />
                      <Typography
                        sx={{
                          color: selectedCard?.id === cardItem.id ? COLORS.WHITE : COLORS.MAIN_DARK,
                          fontWeight: 600,
                          fontSize: '14px',
                          lineHeight: '28px',
                          ml: 4,
                        }}
                        variant="body2"
                      >
                        {t('dashboard.newWalletCard.card')}
                      </Typography>
                    </Grid>
                    {cardItem.isPrimaryCard && (
                      <Grid item>
                        <Typography
                          sx={{
                            color: selectedCard?.id === cardItem.id ? COLORS.WHITE : COLORS.PRIMARY_BLUE,
                            fontWeight: 600,
                            fontSize: '14px',
                            lineHeight: '20px',
                          }}
                          variant="body1"
                        >
                          {t('primary')}
                        </Typography>
                      </Grid>
                    )}
                    {!cardItem.isPrimaryCard && (
                      <Grid item>
                        <Button
                          sx={{
                            color: selectedCard?.id === cardItem.id ? COLORS.WHITE : COLORS.PRIMARY_BLUE,
                            textTransform: 'none',
                          }}
                          onClick={(e) => onSetPrimaryCard(e, cardItem.id)}
                        >
                          <Typography
                            sx={{
                              color: selectedCard?.id === cardItem.id ? COLORS.WHITE : COLORS.PRIMARY_BLUE,
                              '@media (max-width:768px)': {
                                fontSize: '10px',
                                lineHeight: '20px',
                              },
                              '@media (max-width:640px)': {
                                fontSize: '9px',
                                lineHeight: '20px',
                              },
                            }}
                            variant="bodySmall"
                          >
                            {t('setPrimary')}
                          </Typography>
                        </Button>
                      </Grid>
                    )}
                  </Grid>
                  <Grid item sx={{ display: 'flex', alignItems: 'center' }}>
                    {cardItem.paymentBrand && (
                      <img
                        width={42}
                        height={14}
                        src={`/images/card-brand/${cardItem.paymentBrand.toLocaleLowerCase()}${
                          selectedCard?.id === cardItem.id ? '-selected' : ''
                        }.png`}
                        alt={`${cardItem.paymentBrand} logo`}
                      />
                    )}
                    <Typography
                      sx={{
                        color: selectedCard?.id === cardItem.id ? COLORS.WHITE : COLORS.MAIN_DARK,
                        fontWeight: 400,
                        fontSize: '16px',
                        lineHeight: '28px',
                        ml: 3,
                      }}
                      variant="body2"
                    >
                      ****&nbsp;****&nbsp;****&nbsp;{cardItem.last4Digits}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      '@media (max-width:400px)': {
                        flexDirection: selectedCard?.id === cardItem.id ? 'column' : 'row',
                        alignItems: selectedCard?.id === cardItem.id ? 'start' : 'center',
                        gap: selectedCard?.id === cardItem.id ? '5px' : '0px',
                      },
                    }}
                  >
                    {cardItem.status === RecurringStatus.ACTIVE && (
                      <Box>
                        <Box
                          sx={{
                            '@media (max-width:600px)': {
                              display: 'none',
                            },
                          }}
                        >
                          <Box
                            onClick={() => {
                              setOpen(!open);
                            }}
                            sx={{
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              p: '8px 16px',
                              backgroundColor: selectedCard?.id === cardItem.id ? '#228FDD' : '#F6FAFB',
                              color: selectedCard?.id === cardItem.id ? COLORS.WHITE : COLORS.PRIMARY_BLUE,
                              fontWeight: 600,
                              fontSize: 14,
                              width: 'fit-content',
                              borderRadius: '4px',
                              '@media (max-width:768px)': {
                                fontSize: '10px',
                              },
                              '@media (max-width:640px)': {
                                fontSize: '9px',
                              },
                            }}
                          >
                            <Box
                              sx={{
                                backgroundColor: COLORS.GREEN,
                                width: 8,
                                height: 8,
                                borderRadius: '50%',
                                mr: 4,
                              }}
                            />
                            {t('dashboard.newWalletCard.recurringPayment')}
                          </Box>
                        </Box>
                        <Box
                          sx={{
                            display: 'none',
                            justifyContent: 'center',
                            alignItems: 'center',
                            p: '8px 16px',
                            backgroundColor: selectedCard?.id === cardItem.id ? '#228FDD' : '#F6FAFB',
                            color: selectedCard?.id === cardItem.id ? COLORS.WHITE : COLORS.PRIMARY_BLUE,
                            fontWeight: 600,
                            fontSize: 14,
                            borderRadius: '4px',
                            '@media (max-width:600px)': {
                              display: 'flex',
                              width: '100%',
                            },
                            '@media (max-width:400px)': {
                              fontSize: '12px',
                            },
                          }}
                        >
                          <Box
                            sx={{
                              backgroundColor: COLORS.GREEN,
                              width: 8,
                              height: 8,
                              borderRadius: '50%',
                              mr: 4,
                            }}
                          />
                          {t('dashboard.newWalletCard.recurringPayment')}
                        </Box>
                      </Box>
                    )}
                  </Grid>
                  <Grid item sx={{ mt: '-8px', width: '100%', display: 'block' }}>
                    <Box
                      sx={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                    >
                      {isScreenSM && (
                        <ClickAwayListener onClickAway={handleTooltipClose} key={cardItem.id}>
                          <Box key={cardItem.id}>
                            <Tooltip
                              arrow
                              PopperProps={{
                                disablePortal: true,
                                popperOptions: {
                                  modifiers: [{ name: 'flip', enabled: false }],
                                },
                                sx: {
                                  display: { xs: 'none', sm: 'block' },
                                  width: '90%',
                                  top: '50px !important',
                                  zIndex: '1111 !important',
                                  '.MuiTooltip-tooltip': {
                                    left: isEnglish ? '32px' : '130px',
                                    bgcolor: '#fff !important',
                                    color: COLORS.BLACK,
                                    width: '100% !important',
                                    maxWidth: 'calc(100% - 160px) !important',
                                    borderRadius: '12px !important',
                                    padding: '50px 32px 32px 32px !important',
                                    boxShadow: '5px 4px 7px 2px #e8e4e4 !important',
                                  },
                                  '.MuiTooltip-arrow': {
                                    color: '#fff !important',
                                    width: '42px !important',
                                    height: '30px !important',
                                    top: '-22px !important',
                                  },
                                  '@media (max-width:900px)': {
                                    '.MuiTooltip-tooltip': {
                                      maxWidth: 'calc(100% - 55px) !important',
                                      left: isEnglish ? '32px' : '24px',
                                    },
                                  },
                                },
                              }}
                              open={tooltipOpenIndex === cardItem.id}
                              title={
                                <Box
                                  key={selectedCard?.id}
                                  sx={{
                                    borderRadius: '12px',
                                    width: '100%',
                                    mt: '-24px',
                                    display: { xs: 'none', sm: 'block' },
                                  }}
                                >
                                  <RecurrentBillingForm
                                    key={cardItem?.id}
                                    subscription={cardItem}
                                    onCloseRecurringSettings={onCloseRecurringSettings}
                                    onOpenUpdatedRecurringNotification={onOpenUpdatedRecurringNotification}
                                  />
                                </Box>
                              }
                            >
                              <Box
                                onClick={() => handleTooltipToggle(cardItem.id)}
                                sx={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  p: '8px 16px',
                                  color: selectedCard?.id === cardItem.id ? COLORS.WHITE : COLORS.PRIMARY_BLUE,
                                  fontWeight: 600,
                                  fontSize: 14,
                                  width: 'fit-content',
                                  borderRadius: '4px',
                                  '@media (max-width:768px)': {
                                    fontSize: '10px',
                                  },
                                  '@media (max-width:640px)': {
                                    fontSize: '9px',
                                  },
                                }}
                              >
                                <Button
                                  startIcon={
                                    <Settings
                                      sx={{
                                        stroke: selectedCard?.id === cardItem.id ? COLORS.WHITE : COLORS.BLACK,
                                        strokeWidth: '1px',
                                        fill: 'transparent',
                                      }}
                                      color={selectedCard?.id === cardItem.id ? COLORS.WHITE : COLORS.BLACK}
                                    />
                                  }
                                  sx={{ color: COLORS.WHITE, p: 0 }}
                                >
                                  <Typography
                                    sx={{
                                      color: selectedCard?.id === cardItem.id ? COLORS.WHITE : COLORS.BLACK,
                                      fontWeight: 400,
                                      fontSize: 'clamp(0.5rem,1vw + 1rem,0.875rem)',
                                      lineHeight: '24px',
                                      textTransform: 'capitalize',
                                    }}
                                    variant="body1"
                                  >
                                    {t('settings')}
                                  </Typography>
                                </Button>
                              </Box>
                            </Tooltip>
                          </Box>
                        </ClickAwayListener>
                      )}
                      {!isScreenSM && (
                        <Button
                          startIcon={
                            <Settings
                              sx={{
                                stroke: selectedCard?.id === cardItem.id ? COLORS.WHITE : COLORS.BLACK,
                                strokeWidth: '1px',
                                fill: 'transparent',
                              }}
                              color={selectedCard?.id === cardItem.id ? COLORS.WHITE : COLORS.BLACK}
                            />
                          }
                          onClick={() => handleTooltipToggle(cardItem.id)}
                          sx={{ color: COLORS.WHITE, p: 0 }}
                        >
                          <Typography
                            sx={{
                              color: selectedCard?.id === cardItem.id ? COLORS.WHITE : COLORS.BLACK,
                              fontWeight: 400,
                              fontSize: 'clamp(0.5rem,1vw + 1rem,0.875rem)',
                              lineHeight: '24px',
                              textTransform: 'capitalize',
                            }}
                            variant="body1"
                          >
                            {t('settings')}
                          </Typography>
                        </Button>
                      )}
                      <Button
                        startIcon={
                          <DeleteForeverOutlinedIcon
                            style={{ stroke: selectedCard?.id === cardItem.id ? COLORS.WHITE : COLORS.BLACK }}
                          />
                        }
                        sx={{
                          color: COLORS.WHITE,
                          p: 0,
                          ml: '0px',
                          '&:hover': {
                            backgroundColor: 'transparent',
                          },
                          '@media (max-width:600px)': {
                            marginLeft: '0px',
                          },
                        }}
                        onClick={(e) => onDeleteSubscription(e, cardItem.id)}
                      >
                        <Typography
                          sx={{
                            color: selectedCard?.id === cardItem.id ? COLORS.WHITE : COLORS.BLACK,
                            fontWeight: 400,
                            fontSize: 'clamp(0.5rem,1vw + 1rem,0.875rem)',
                            lineHeight: '24px',
                            textTransform: 'capitalize',
                          }}
                          variant="body1"
                        >
                          {t('delete')}
                        </Typography>
                      </Button>
                    </Box>
                    <Box
                      sx={{
                        display: { xs: 'block', sm: 'none' },
                        p: 0,
                        borderRadius: '12px',
                        width: '100%',
                        mt: '24px',
                      }}
                    >
                      {openRecurringForm && tooltipOpenIndex === cardItem.id && openRecurringForm[tooltipOpenIndex] && (
                        <RecurrentBillingForm
                          key={cardItem?.id}
                          subscription={cardItem}
                          onCloseRecurringSettings={onCloseRecurringSettings}
                          onOpenUpdatedRecurringNotification={onOpenUpdatedRecurringNotification}
                        />
                      )}
                    </Box>
                  </Grid>
                </Grid>
                {/* credit card */}
              </Box>
            );
          })}
        </Grid>
      ) : (
        <Box sx={{ textAlign: 'center', mt: '24px' }}>{t('noAvailableCards')}</Box>
      )}
    </>
  );

  return isLoadingSubscriptions ? (
    <CircularProgress />
  ) : (
    <>
      {renderContent()}
      <Snackbar
        sx={{
          maxWidth: 'unset',
          minWidth: 'unset',
          width: 'fit-content',
          m: 'auto',
          '& .MuiPaper-root': {
            maxWidth: 'unset',
            minWidth: 'unset',
            width: 'fit-content',
            m: 'auto',
          },
        }}
        open={isRecurringChanged}
        autoHideDuration={10000}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        onClose={onCloseUpdatedRecurringNotification}
      >
        <Alert severity="success" variant="filled" sx={{ width: '100%' }} onClose={onCloseUpdatedRecurringNotification}>
          {t('userProfile.settingsupdated')}
        </Alert>
      </Snackbar>
    </>
  );
};

export default NewCards;
