import { LOGOUT } from '../types/authTypes';
import {
  CANCEL_SUBSCRIPTION_FAIL,
  CANCEL_SUBSCRIPTION_REQUEST,
  CANCEL_SUBSCRIPTION_SUCCESS,
  CLEAR_PORTFOLIO,
  DO_ADD_MORE_FUND_TO_PORTFOLIO_FAIL,
  DO_ADD_MORE_FUND_TO_PORTFOLIO_REQUEST,
  DO_ADD_MORE_FUND_TO_PORTFOLIO_SUCCESS,
  DO_SUBSCRIBE_TO_PORTFOLIO_FAIL,
  DO_SUBSCRIBE_TO_PORTFOLIO_REQUEST,
  DO_SUBSCRIBE_TO_PORTFOLIO_SUCCESS,
  GET_AVAILABLE_PORTFOLIOS_FAIL,
  GET_AVAILABLE_PORTFOLIOS_REQUEST,
  GET_AVAILABLE_PORTFOLIOS_SUCCESS,
  GET_PORTFOLIO_LATEST_STATISTICS_FAIL,
  GET_PORTFOLIO_LATEST_STATISTICS_REQUEST,
  GET_PORTFOLIO_LATEST_STATISTICS_SUCCESS,
  GET_PORTFOLIO_STATISTICS_FAIL,
  GET_PORTFOLIO_STATISTICS_REQUEST,
  GET_PORTFOLIO_STATISTICS_SUCCESS,
  GET_RECOMMENDED_PORTFOLIO_FAIL,
  GET_RECOMMENDED_PORTFOLIO_REQUEST,
  GET_RECOMMENDED_PORTFOLIO_SUCCESS,
  GET_SUBSCRIBED_PORTFOLIO_FAIL,
  GET_SUBSCRIBED_PORTFOLIO_REQUEST,
  GET_SUBSCRIBED_PORTFOLIO_SUCCESS,
  GET_SUBSCRIPTIONS_HISTORY_FAIL,
  GET_SUBSCRIPTIONS_HISTORY_REQUEST,
  GET_SUBSCRIPTIONS_HISTORY_SUCCESS,
  PortfolioAction,
  PortfolioState,
  RESET_REDEMPTION,
  REDEEM_PORTFOLIO_FAIL,
  REDEEM_PORTFOLIO_REQUEST,
  REDEEM_PORTFOLIO_SUCCESS,
  RESET_SUBSCRIBE_TO_PORTFOLIO,
  SubscribedPortfolioStatus,
} from '../types/portfolioTypes';

const INITIAL_STATE: PortfolioState = {
  recommendedPortfolio: {
    id: 0,
    files: [],
    associatedIndexFunds: [],
    nameAr: '',
    nameEn: '',
    descriptionAr: '',
    descriptionEn: '',
    objectiveEn: '',
    objectiveAr: '',
    shariaaComplaint: false,
    expectedMinimumReturn: 0,
    targetExpectedReturn: 0,
    expectedMaximumReturn: 0,
    targetExpectedRisk: 0,
    minimumFundAmount: 0,
    benchmark: '',
    sharpRatio: 0,
    createdBy: '',
    isLoading: false,
    isErrorExist: false,
    createdAt: '',
    updatedAt: '',
    displayByGics: false,
    productType: {
      id: 0,
      nameEn: '',
      nameAr: '',
      descriptionEn: '',
      descriptionAr: '',
      minimumPortfolioInvestment: 0,
      minimumAddFundInvestment: 0,
      scoreDependent: false,
      isCustom: false,
      disabled: false,
      files: [],
      standartDeviation: 0,
      createdAt: new Date(),
      updatedAt: new Date(),
    },
    userCategory: {
      createdAt: '',
      descriptionAr: '',
      descriptionEn: '',
      id: 0,
      nameAr: '',
      minScore: 0,
      maxScore: 0,
      nameEn: 'Moderate Investor',
      updatedAt: new Date(),
    },
  },
  subscribedPortfolio: {
    id: 0,
    files: [],
    associatedIndexFunds: [],
    userPortfolios: [],
    nameAr: '',
    nameEn: '',
    descriptionAr: '',
    descriptionEn: '',
    objectiveEn: '',
    objectiveAr: '',
    shariaaComplaint: false,
    expectedMinimumReturn: 0,
    targetExpectedReturn: 0,
    expectedMaximumReturn: 0,
    targetExpectedRisk: 0,
    minimumFundAmount: 0,
    benchmark: '',
    sharpRatio: 0,
    status: { id: 0, name: SubscribedPortfolioStatus.PENDING },
    isLoading: false,
    isErrorExist: false,
    displayByGics: false,
    productType: {
      id: 0,
      nameEn: '',
      nameAr: '',
      descriptionEn: '',
      descriptionAr: '',
      minimumPortfolioInvestment: 0,
      minimumAddFundInvestment: 0,
      scoreDependent: false,
      isCustom: false,
      disabled: false,
      files: [],
      standartDeviation: 0,
      createdAt: new Date(),
      updatedAt: new Date(),
    },
    userCategory: {
      createdAt: '',
      descriptionAr: '',
      descriptionEn: '',
      id: 0,
      nameAr: '',
      nameEn: 'Moderate Investor',
      minScore: 0,
      maxScore: 0,
      updatedAt: new Date(),
    },
  },
  subscribedPortfolios: [],
  subscribeToPortfolio: {
    result: {},
    error: '',
    isLoading: false,
    isErrorExist: false,
  },
  addMoreFundToPortfolio: {
    result: {},
    error: '',
    isLoading: false,
    isErrorExist: false,
  },
  subscriptionsHistory: {
    data: {
      items: [],
      totalItems: 0,
    },
    error: '',
    isLoading: false,
    isErrorExist: false,
  },
  redemption: {
    error: '',
    isLoading: false,
    isErrorExist: false,
  },
  cancelSubscription: {
    error: '',
    isLoading: false,
    isErrorExist: false,
  },
  statistics: {
    // latest: { id: 0, totalReturn: 45, totalMarketValue: 5000, netDeposit: 55000, totalAmount: 24000, netWithdrawals: 12000, createdAt: new Date(), updatedAt: new Date(), date: '', userId: 1 },
    latest: null,
    all: [],
    error: '',
    isLoading: false,
    isErrorExist: false,
  },
  availablePortfolios: {
    data: {
      items: [],
      totalItems: 0,
    },
    error: '',
    isLoading: false,
    isErrorExist: false,
  },
};

/**
 * TODO: We must migrate to `@reduxjs/toolkit`!
 * @deprecated
 */
const portfolioReducer = (
  state: PortfolioState = INITIAL_STATE,
  action: PortfolioAction = { type: LOGOUT },
): PortfolioState => {
  switch (action.type) {
    case GET_RECOMMENDED_PORTFOLIO_REQUEST:
      return {
        ...state,
        recommendedPortfolio: {
          ...state.recommendedPortfolio,
          isLoading: true,
        },
      };

    case GET_RECOMMENDED_PORTFOLIO_SUCCESS:
      return {
        ...state,
        recommendedPortfolio: {
          ...state.recommendedPortfolio,
          ...(action.payload || {}),
          isLoading: false,
          isErrorExist: false,
        },
      };

    case GET_RECOMMENDED_PORTFOLIO_FAIL:
      return {
        ...state,
        recommendedPortfolio: {
          ...state.recommendedPortfolio,
          isLoading: false,
          isErrorExist: true,
        },
      };

    case GET_SUBSCRIBED_PORTFOLIO_REQUEST:
      return {
        ...state,
        subscribedPortfolio: {
          ...state.subscribedPortfolio,
          isLoading: true,
        },
      };

    case GET_SUBSCRIBED_PORTFOLIO_SUCCESS:
      return {
        ...state,
        subscribedPortfolios: action.payload.items,
      };

    case GET_SUBSCRIBED_PORTFOLIO_FAIL:
      return {
        ...state,
        subscribedPortfolio: {
          ...state.subscribedPortfolio,
          isLoading: false,
          isErrorExist: true,
        },
      };

    case DO_SUBSCRIBE_TO_PORTFOLIO_REQUEST:
      return {
        ...state,
        subscribeToPortfolio: {
          ...state.subscribeToPortfolio,
          error: '',
          isLoading: true,
        },
      };

    case DO_SUBSCRIBE_TO_PORTFOLIO_SUCCESS:
      return {
        ...state,
        subscribeToPortfolio: {
          ...state.subscribeToPortfolio,
          error: '',
          result: action.payload,
          isLoading: false,
          isErrorExist: false,
        },
      };

    case DO_SUBSCRIBE_TO_PORTFOLIO_FAIL:
      return {
        ...state,
        subscribeToPortfolio: {
          ...state.subscribeToPortfolio,
          error: action.payload,
          isLoading: false,
          isErrorExist: true,
        },
      };

    case DO_ADD_MORE_FUND_TO_PORTFOLIO_REQUEST:
      return {
        ...state,
        addMoreFundToPortfolio: {
          ...state.addMoreFundToPortfolio,
          error: '',
          isLoading: true,
        },
      };

    case DO_ADD_MORE_FUND_TO_PORTFOLIO_SUCCESS:
      return {
        ...state,
        addMoreFundToPortfolio: {
          ...state.addMoreFundToPortfolio,
          error: '',
          result: action.payload,
          isLoading: false,
          isErrorExist: false,
        },
      };

    case DO_ADD_MORE_FUND_TO_PORTFOLIO_FAIL:
      return {
        ...state,
        addMoreFundToPortfolio: {
          ...state.addMoreFundToPortfolio,
          error: action.payload,
          isLoading: false,
          isErrorExist: true,
        },
      };

    case RESET_SUBSCRIBE_TO_PORTFOLIO:
      return {
        ...state,
        subscribeToPortfolio: {
          ...state.subscribeToPortfolio,
          error: '',
          isLoading: false,
          isErrorExist: false,
        },
      };

    case GET_SUBSCRIPTIONS_HISTORY_REQUEST:
      return {
        ...state,
        subscriptionsHistory: {
          ...state.subscriptionsHistory,
          error: '',
          isLoading: true,
        },
      };

    case GET_SUBSCRIPTIONS_HISTORY_SUCCESS:
      return {
        ...state,
        subscriptionsHistory: {
          ...state.subscriptionsHistory,
          error: '',
          data: action.payload.isLoadMore
            ? {
                items: [...state.subscriptionsHistory.data.items, ...action.payload.result.items],
                totalItems: action.payload.result.totalItems,
              }
            : action.payload.result,
          isLoading: false,
          isErrorExist: false,
        },
      };

    case GET_SUBSCRIPTIONS_HISTORY_FAIL:
      return {
        ...state,
        subscriptionsHistory: {
          ...state.subscriptionsHistory,
          error: action.payload,
          isLoading: false,
          isErrorExist: true,
        },
      };

    case REDEEM_PORTFOLIO_REQUEST:
      return {
        ...state,
        redemption: {
          ...state.redemption,
          error: '',
          isLoading: true,
        },
      };

    case REDEEM_PORTFOLIO_SUCCESS:
      return {
        ...state,
        redemption: {
          ...state.redemption,
          error: '',
          isLoading: false,
          isErrorExist: false,
        },
      };

    case REDEEM_PORTFOLIO_FAIL:
      return {
        ...state,
        redemption: {
          ...state.redemption,
          error: action.payload,
          isLoading: false,
          isErrorExist: true,
        },
      };

    case CANCEL_SUBSCRIPTION_REQUEST:
      return {
        ...state,
        cancelSubscription: {
          ...state.cancelSubscription,
          error: '',
          isLoading: true,
        },
      };

    case CANCEL_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        cancelSubscription: {
          ...state.cancelSubscription,
          error: '',
          isLoading: false,
          isErrorExist: false,
        },
      };

    case CANCEL_SUBSCRIPTION_FAIL:
      return {
        ...state,
        cancelSubscription: {
          ...state.cancelSubscription,
          error: action.payload,
          isLoading: false,
          isErrorExist: true,
        },
      };

    case GET_PORTFOLIO_LATEST_STATISTICS_REQUEST:
    case GET_PORTFOLIO_STATISTICS_REQUEST:
      return {
        ...state,
        statistics: {
          ...state.statistics,
          error: '',
          isLoading: true,
        },
      };

    case GET_PORTFOLIO_LATEST_STATISTICS_SUCCESS:
      return {
        ...state,
        statistics: {
          ...state.statistics,
          latest: action.payload,
          error: '',
          isLoading: false,
          isErrorExist: false,
        },
      };

    case GET_PORTFOLIO_LATEST_STATISTICS_FAIL:
    case GET_PORTFOLIO_STATISTICS_FAIL:
      return {
        ...state,
        statistics: {
          ...state.statistics,
          error: action.payload,
          isLoading: false,
          isErrorExist: true,
        },
      };

    case GET_PORTFOLIO_STATISTICS_SUCCESS:
      return {
        ...state,
        statistics: {
          ...state.statistics,
          all: action.payload,
          error: '',
          isLoading: false,
          isErrorExist: false,
        },
      };

    case GET_AVAILABLE_PORTFOLIOS_REQUEST:
      return {
        ...state,
        availablePortfolios: {
          ...state.availablePortfolios,
          isLoading: true,
          isErrorExist: false,
        },
      };

    case GET_AVAILABLE_PORTFOLIOS_SUCCESS:
      return {
        ...state,
        availablePortfolios: {
          ...state.availablePortfolios,
          data: {
            ...action.payload,
          },
          isLoading: false,
          isErrorExist: false,
        },
      };

    case GET_AVAILABLE_PORTFOLIOS_FAIL:
      return {
        ...state,
        availablePortfolios: {
          ...state.availablePortfolios,
          isLoading: false,
          isErrorExist: true,
          error: action.payload,
        },
      };

    case CLEAR_PORTFOLIO:
      return { ...INITIAL_STATE };

    case RESET_REDEMPTION:
      return {
        ...state,
        redemption: INITIAL_STATE.redemption,
      };

    case LOGOUT:
      return { ...INITIAL_STATE };

    default:
      return state;
  }
};

export default portfolioReducer;
