import React, { useEffect, useImperativeHandle, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import moment from 'moment';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { Modal } from '@mui/material';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import COLORS from '../../../constants/colors';
import { useCheckUserAuthQuery, useFindProfileDataQuery, useSendProfileUpdateOTPMutation } from '../../../redux/features/auth/auth.apiSlice';
import { authSelector } from '../../../redux/features/auth/auth.selectors';
import { useTypedDispatch } from '../../../redux/store';
import { getProfileDataFormSchema } from '../../../forms/validationSchema/formSchema';
import { profileDataFormFields } from '../../../forms/fields/profileData';
import { MTextField } from '../../../shared/MTextField';
import { MobileField } from '../../../shared/MobileField';
import { UpdateProfileDataPayload } from '../../../store_deprecated/types/authTypes';
import { findProfileData, updateProfileData } from '../../../store_deprecated/actions/authActions';
import ChangeEmailAndMobileSucessModal from './ChangeEmailAndMobileSucessModal';
import MButton from '../../../shared/MButton';
import OTPInput from '../Login/OTPInput';
import CountDown from '../Login/CountDown';
import { locations } from '../../../routes/locations';

interface ProfileDataSectionProps {
  editMode: boolean;
}

export interface ProfileDataSectionRef {
  submit: () => Promise<any>;
  isValid: () => boolean;
}

const OTP_INPUT_LENGTH = 4;

const SubmitButton = () => {
  const { t, i18n } = useTranslation('auth', { useSuspense: true });
  return (
    <MButton
      fullWidth
      variant="contained"
      buttonProps={{
        type: 'submit',
        sx: {
          mt: { xs: 0, md: 3 },
          mb: 6,
          width: { xs: '100%', md: '100%' },
          height: { xs: '50px', md: 60 },
          backgroundColor: COLORS?.X_DARK_BLUE,
        },
      }}
      typographyProps={{
        variant: i18n.language === 'en' ? 'h6' : 'cairoH4',
        sx: { textTransform: 'capitalize', color: 'white' },
      }}
    >
      {t('submit')}
    </MButton>
  );
};

const ProfileDataSection = React.forwardRef<ProfileDataSectionRef, ProfileDataSectionProps>((props, ref) => {
  const navigate = useNavigate();
  const errRef = useRef<any>();
  const { t, i18n } = useTranslation();
  const { i18n: i18nApiError } = useTranslation('apiError');
  const isEnglish = i18n.language === 'en';
  const dispatch = useTypedDispatch();
  const { user } = useSelector(authSelector);
  const { refetch: refetchCheckAuth } = useCheckUserAuthQuery();
  const { data, isLoading } = useFindProfileDataQuery();
  const [sendProfileUpdateOTP] = useSendProfileUpdateOTPMutation();
  const [phoneSent, setPhoneSent] = useState('');
  const [emailSent, setEmailSent] = useState('');
  const [phoneOtpLoading, setPhoneOtpLoading] = useState(false);
  const [emailOtpLoading, setEmailOtpLoading] = useState(false);
  const [otpSendTime, setOtpSendTime] = useState(0);
  const [openModalOtp, setOpenModalOtp] = useState(false);
  const [successModal, setSuccessModal] = useState(false);
  const [phoneotp, setPhoneOtp] = useState('');
  const [emailotp, setEmailOtp] = useState('');
  const [isPhone, setIsPhone] = useState(false);
  const [phoneDisable, setPhoneDisable] = useState(true);
  const [emailDisable, setEmailDisable] = useState(true);
  const [formSchema, setFormSchema] = useState(getProfileDataFormSchema({ emailChanged: false, phoneChanged: false }));
  const [customErrors, setCustomErrors] = useState<Record<string, string>>({});
  const [initialValues, setInitialValues] = useState({
    [profileDataFormFields.fullName]: user?.fullName || '',
    [profileDataFormFields.email]: user?.email,
    [profileDataFormFields.emailOtp]: '',
    [profileDataFormFields.phone]: user?.mobile,
    [profileDataFormFields.phoneOtp]: '',
    [profileDataFormFields.birthDate]: data?.birthDate
      ? moment(data.birthDate).locale(i18n.language).format('DD-MMM-YYYY')
      : '',
    // [profileDataFormFields.address]: data?.address || "",
    [profileDataFormFields.employerAddress]: data?.employerAddress || '',
    [profileDataFormFields.companyName]: data?.companyName || '',
    [profileDataFormFields.nationalId]: data?.nationalId || '',
  });

  const { submitForm, values, touched, errors, isValid, getFieldProps, isSubmitting, resetForm, setTouched } =
    useFormik({
      initialValues,
      initialTouched: {
        [profileDataFormFields.phone]: true,
        [profileDataFormFields.phoneOtp]: true,
        [profileDataFormFields.email]: true,
        [profileDataFormFields.emailOtp]: true,
        // [profileDataFormFields.address]: true,
        [profileDataFormFields.employerAddress]: true,
        [profileDataFormFields.companyName]: true,
      },
      validationSchema: formSchema,
      onSubmit: async (formValues) => {
        const castValues = formSchema.cast(formValues);

        const payload: UpdateProfileDataPayload = {
          employerAddress: castValues.employerAddress,
          companyName: castValues.companyName,
        };

        const res = (await dispatch(updateProfileData(payload))) as any;

        if (typeof res === 'string') {
          if (res === 'INVALID_EMAIL_OTP_CODE') {
            setCustomErrors((prev) => ({ ...prev, emailOtp: ' ' }));
          }
          if (res === 'INVALID_MOBILE_OTP_CODE') {
            setCustomErrors((prev) => ({ ...prev, phoneOtp: ' ' }));
          }
        }

        return res;
      },
    });

  useImperativeHandle(ref, () => ({
    submit: async () => {
      await setTouched(Object.keys(values).reduce((res, key) => ({ ...res, [key]: true }), {}));

      return submitForm();
    },
    isValid: () => isValid,
  }));

  const emailChanged = values[profileDataFormFields.email] !== user?.email;
  const phoneChanged = values[profileDataFormFields.phone] !== user?.mobile;

  const items = [
    { title: 'userProfile.fullName', value: user?.fullName },
    // { title: "userProfile.address", value: data?.address || "" },
    { title: 'userProfile.mobileNumber', value: user?.mobile },
    {
      title: 'userProfile.employerAddress',
      value: data?.employerAddress || '',
    },
    { title: 'userProfile.email', value: user?.email },
    { title: 'userProfile.organization', value: data?.companyName || '' },
    {
      title: 'userProfile.birthDate',
      value: data?.birthDate ? moment(data.birthDate).locale(i18n.language).format('DD-MMM-YYYY') : '',
    },
    { title: 'userProfile.nationalId', value: data?.nationalId || '' },
  ];

  useEffect(() => {
    setFormSchema(getProfileDataFormSchema({ emailChanged, phoneChanged }));
  }, [emailChanged, phoneChanged]);

  useEffect(() => {
    const fetch = async () => {
      if (!props.editMode) {
        setPhoneSent('');
        setEmailSent('');
        setPhoneOtpLoading(false);
        setEmailOtpLoading(false);
        setFormSchema(getProfileDataFormSchema({ emailChanged: true, phoneChanged: true }));
        setCustomErrors({});
        resetForm();
        await dispatch(findProfileData());
      }
      fetch();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.editMode]);

  useEffect(() => {
    const newValues = {
      [profileDataFormFields.fullName]: user?.fullName || '',
      [profileDataFormFields.email]: user?.email || '',
      [profileDataFormFields.emailOtp]: '',
      [profileDataFormFields.phone]: user?.mobile || '',
      [profileDataFormFields.phoneOtp]: '',
      [profileDataFormFields.birthDate]: data?.birthDate
        ? moment(data.birthDate).locale(i18n.language).format('DD-MMM-YYYY')
        : '',
      // [profileDataFormFields.address]: data?.address || "",
      [profileDataFormFields.employerAddress]: data?.employerAddress || '',
      [profileDataFormFields.companyName]: data?.companyName || '',
      [profileDataFormFields.nationalId]: data?.nationalId || '',
    };

    setInitialValues(newValues);

    resetForm({ values: newValues });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, user]);

  useEffect(() => {
    setCustomErrors((prev) => ({ ...prev, emailOtp: '', phoneOtp: '' }));
  }, [
    // eslint-disable-next-line react-hooks/exhaustive-deps
    values[profileDataFormFields.phoneOtp],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    values[profileDataFormFields.emailOtp],
  ]);

  const onPhoneBlur = async () => {
    if (
      values[profileDataFormFields.phone] &&
      phoneChanged &&
      phoneSent !== values[profileDataFormFields.phone] &&
      !errors.phone
    ) {
      setCustomErrors((prev) => ({ ...prev, phone: '' }));
      setPhoneOtpLoading(true);
      try {
        setPhoneSent(values[profileDataFormFields.phone]);

        await sendProfileUpdateOTP({
          mobileNumber: values[profileDataFormFields.phone],
        }).unwrap();
        setOpenModalOtp(true);
        setIsPhone(true);
        setOtpSendTime(Date.now());
      } catch (error: any) {
        if (i18nApiError.exists(`apiError:${error?.data?.message}`)) {
          setCustomErrors((prev) => ({
            ...prev,
            phone: `apiError:${error?.data?.message}`,
          }));
        }
      }
      setPhoneOtpLoading(false);
    } else {
      setCustomErrors((prev) => ({ ...prev, phone: t('userAlreadyExists') }));
    }
  };

  const onEmailBlur = async () => {
    if (
      values[profileDataFormFields.email] &&
      emailSent !== values[profileDataFormFields.email] &&
      emailChanged &&
      !errors.email
    ) {
      setCustomErrors((prev) => ({ ...prev, email: '' }));
      setEmailOtpLoading(true);
      try {
        setEmailSent(values[profileDataFormFields.email]);

        await sendProfileUpdateOTP({
          email: values[profileDataFormFields.email],
        }).unwrap();
        setOpenModalOtp(true);
        setIsPhone(false);
        setOtpSendTime(Date.now());
      } catch (error: any) {
        if (i18nApiError.exists(`apiError:${error?.data?.message}`)) {
          setCustomErrors((prev) => ({
            ...prev,
            email: `apiError:${error?.data?.message}`,
          }));
        }
      }
      setEmailOtpLoading(false);
    } else {
      setCustomErrors((prev) => ({ ...prev, email: t('userAlreadyExists') }));
    }
  };

  const resendOTP = async () => {
    setCustomErrors({});
    setPhoneOtp('');
    setEmailOtp('');
    setOtpSendTime(Date.now());
    if (isPhone && values[profileDataFormFields.phone]) {
      await sendProfileUpdateOTP({
        mobileNumber: values[profileDataFormFields.phone] as string, // Use type assertion or safe casting
      }).unwrap();
    } else if (!isPhone && values[profileDataFormFields.email]) {
      await sendProfileUpdateOTP({
        email: values[profileDataFormFields.email] as string, // Use type assertion or safe casting
      }).unwrap();
    }
  };

  const handleSubmitOtp = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const emailPayload: UpdateProfileDataPayload = {
      email: values[profileDataFormFields.email],
      employerAddress: values[profileDataFormFields.employerAddress],
      companyName: values[profileDataFormFields.email],
      emailOtp: emailotp || '',
    };
    const mobilePayload: UpdateProfileDataPayload = {
      phone: values[profileDataFormFields.phone],
      employerAddress: values[profileDataFormFields.employerAddress],
      companyName: values[profileDataFormFields.email],
      phoneOtp: phoneotp || '',
    };

    const res = (await dispatch(updateProfileData(isPhone ? mobilePayload : emailPayload))) as any;
    if (typeof res === 'string') {
      if (res === 'INVALID_EMAIL_OTP_CODE') {
        setCustomErrors((prev) => ({ ...prev, emailOtp: 'iNVALIDEMAILOTPCODE' }));
      }
      if (res === 'INVALID_MOBILE_OTP_CODE') {
        setCustomErrors((prev) => ({ ...prev, phoneOtp: 'iNVALIDMOBILEOTPCODE' }));
      }
    } else {
      setOpenModalOtp(false);
      setSuccessModal(true);
    }
  };

  const onInputOtp = (event: React.KeyboardEvent<HTMLInputElement>) => {
    // @ts-ignore
    if (event.target.value) {
      // @ts-ignore
      event.target.value = Math.max(
        0,
        // @ts-ignore
        parseInt(event.target.value, 10).toString().slice(0, 6),
      );
    }
  };

  const onKeyDownOtp = (event: React.KeyboardEvent<HTMLInputElement>) =>
    ['e', 'E', '-', '.', ','].includes(event.key) && event.preventDefault();

  const renderLoaderInInput = () => (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute',
        top: 0,
        bottom: 0,
        right: 12,
      }}
    >
      <CircularProgress size={30} />
    </Box>
  );

  const renderEditForm = () => (
    <Grid item container display="flex" columnSpacing={6} component="form" noValidate>
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isSubmitting}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Grid item mb={8} md={6} sm={12} xs={12} lg={6} display="flex" flexDirection="column">
        <Typography
          variant={isEnglish ? 'bodyMedium' : 'cairoM'}
          sx={{ textTransform: 'capitalize', color: COLORS.LIGHT_GRAY }}
        >
          {t('userProfile.fullName')}
        </Typography>
        <MTextField
          fullWidth
          required
          disabled
          margin="none"
          variant="outlined"
          error={touched.fullName && !!errors.fullName}
          helperText={touched.fullName && t(errors.fullName as string)}
          autoComplete="fullName"
          placeholder={t('userProfile.enterFullName')}
          {...getFieldProps(profileDataFormFields.fullName)}
        />
      </Grid>
      <Grid item container mb={8} md={6} sm={12} xs={12} lg={6} display="flex" flexDirection="column">
        <Grid item container>
          <Grid item flexGrow={1}>
            <Typography
              variant={isEnglish ? 'bodyMedium' : 'cairoM'}
              sx={{ textTransform: 'capitalize', color: COLORS.LIGHT_GRAY }}
            >
              {t('userProfile.mobileNumber')}
            </Typography>
          </Grid>
        </Grid>
        <Grid item container display="flex" flexDirection="row" flexWrap="nowrap">
          <Grid item flexGrow={1} position="relative">
            <MobileField
              fullWidth
              required
              margin="none"
              variant="outlined"
              autoComplete="phone"
              placeholder={t('userProfile.enterPhone')}
              disabled={phoneOtpLoading || phoneDisable}
              {...getFieldProps(profileDataFormFields.phone)}
              error={touched.phone && (!!errors.phone || !!customErrors?.phone)}
              helperText={touched.phone && (t(errors.phone as string) || t(customErrors?.phone))}
            />
            {phoneOtpLoading && renderLoaderInInput()}
          </Grid>
          <Grid item flexGrow={0} minWidth={85} maxWidth={85} ml={1}>
            {phoneDisable ?
              <MButton
                variant="outlined"
                id="EditProfileButton"
                typographyProps={{ sx: { p: { xs: '8px', md: '8px 24px' } } }}
                buttonProps={{
                  sx: { mt: { xs: '8px', md: 'unset' }, boxShadow: 'none' },
                }}
                onClick={() => setPhoneDisable(false)}
              >
                <EditOutlinedIcon sx={{ mr: '8px' }} />
              </MButton> :
              <MButton
                variant="outlined"
                id="EditProfileButton"
                typographyProps={{ sx: { p: { xs: '8px', md: '8px 24px' } } }}
                buttonProps={{
                  sx: { mt: { xs: '8px', md: 'unset' }, boxShadow: 'none' },
                }}
                onClick={onPhoneBlur}
              >
                {t('verify')}
              </MButton>}
          </Grid>
        </Grid>
      </Grid>
      <Grid item mb={8} md={6} sm={12} xs={12} lg={6} display="flex" flexDirection="column">
        <Typography
          variant={isEnglish ? 'bodyMedium' : 'cairoM'}
          sx={{ textTransform: 'capitalize', color: COLORS.LIGHT_GRAY }}
        >
          {t('userProfile.employerAddress')}
        </Typography>
        <MTextField
          fullWidth
          required
          margin="none"
          variant="outlined"
          error={touched.employerAddress && !!errors.employerAddress}
          helperText={touched.employerAddress && t(errors.employerAddress as string)}
          autoComplete="employerAddress"
          placeholder={t('userProfile.employerAddressPlaceholder')}
          {...getFieldProps(profileDataFormFields.employerAddress)}
        />
      </Grid>
      <Grid item container mb={8} md={6} sm={12} xs={12} lg={6} display="flex" flexDirection="column">
        <Grid item container>
          <Grid item flexGrow={1}>
            <Typography
              variant={isEnglish ? 'bodyMedium' : 'cairoM'}
              sx={{ textTransform: 'capitalize', color: COLORS.LIGHT_GRAY }}
            >
              {t('userProfile.email')}
            </Typography>
          </Grid>
        </Grid>
        <Grid item container display="flex" flexDirection="row" flexWrap="nowrap">
          <Grid item flexGrow={1} position="relative">
            <MTextField
              fullWidth
              required
              margin="none"
              variant="outlined"
              autoComplete="email"
              placeholder={t('userProfile.enterEmail')}
              disabled={emailOtpLoading || emailDisable}
              {...getFieldProps(profileDataFormFields.email)}
              error={touched.email && (!!errors.email || !!customErrors?.email)}
              helperText={touched.email && (t(errors.email as string) || t(customErrors?.email))}
            />
            {emailOtpLoading && renderLoaderInInput()}
          </Grid>
          <Grid item flexGrow={0} minWidth={85} maxWidth={85} ml={1}>
            {emailDisable ?
              <MButton
                variant="outlined"
                id="EditProfileButton"
                typographyProps={{ sx: { p: { xs: '8px', md: '8px 24px' } } }}
                buttonProps={{
                  sx: { mt: { xs: '8px', md: 'unset' }, boxShadow: 'none' },
                }}
                onClick={() => setEmailDisable(false)}
              >
                <EditOutlinedIcon sx={{ mr: '8px' }} />
              </MButton> :
              <MButton
                variant="outlined"
                id="EditProfileButton"
                typographyProps={{ sx: { p: { xs: '8px', md: '8px 24px' } } }}
                buttonProps={{
                  sx: { mt: { xs: '8px', md: 'unset' }, boxShadow: 'none' },
                }}
                onClick={onEmailBlur}
              >
                {t('verify')}
              </MButton>
            }
          </Grid>
        </Grid>
      </Grid>
      <Grid item mb={8} md={6} sm={12} xs={12} lg={6} display="flex" flexDirection="column">
        <Typography
          variant={isEnglish ? 'bodyMedium' : 'cairoM'}
          sx={{ textTransform: 'capitalize', color: COLORS.LIGHT_GRAY }}
        >
          {t('userProfile.organization')}
        </Typography>
        <MTextField
          fullWidth
          required
          margin="none"
          variant="outlined"
          error={touched.companyName && !!errors.companyName}
          helperText={touched.companyName && t(errors.companyName as string)}
          autoComplete="companyName"
          placeholder={t('userProfile.enterOrganization')}
          {...getFieldProps(profileDataFormFields.companyName)}
        />
      </Grid>
      <Grid item mb={8} md={6} sm={12} xs={12} lg={6} display="flex" flexDirection="column">
        <Typography
          variant={isEnglish ? 'bodyMedium' : 'cairoM'}
          sx={{ textTransform: 'capitalize', color: COLORS.LIGHT_GRAY }}
        >
          {t('userProfile.birthDate')}
        </Typography>
        <MTextField
          fullWidth
          required
          disabled
          margin="none"
          variant="outlined"
          error={touched.birthDate && !!errors.birthDate}
          helperText={touched.birthDate && t(errors.birthDate as string)}
          autoComplete="birthDate"
          placeholder={t('userProfile.enterBirthDate')}
          {...getFieldProps(profileDataFormFields.birthDate)}
        />
      </Grid>
      <Grid item mb={8} md={6} sm={12} xs={12} lg={6} display="flex" flexDirection="column">
        <Typography
          variant={isEnglish ? 'bodyMedium' : 'cairoM'}
          sx={{ textTransform: 'capitalize', color: COLORS.LIGHT_GRAY }}
        >
          {t('userProfile.nationalId')}
        </Typography>
        <MTextField
          fullWidth
          required
          disabled
          margin="none"
          variant="outlined"
          error={touched.nationalId && !!errors.nationalId}
          helperText={touched.nationalId && t(errors.nationalId as string)}
          autoComplete="nationalId"
          placeholder={t('userProfile.enterNationalId')}
          {...getFieldProps(profileDataFormFields.nationalId)}
        />
      </Grid>
    </Grid>
  );

  const renderContent = () => {
    if (isLoading) {
      return (
        <Grid container display="flex" justifyContent="center" alignItems="center">
          <Grid item>
            <CircularProgress />
          </Grid>
        </Grid>
      );
    }

    return (
      <Grid item container display="flex" columnSpacing={6}>
        {!props.editMode &&
          items.map(({ value, title }, i: number) => (
            <Grid
              key={`profile-data-${title}`}
              item
              xs={12}
              sm={12}
              md={6}
              lg={6}
              display="flex"
              flexDirection="column"
              sx={i < items.length - 1 ? { mb: '32px' } : {}}
            >
              <Typography variant="bodyMedium" sx={{ textTransform: 'capitalize', color: COLORS.LIGHT_GRAY }}>
                {t(title)}
              </Typography>
              <Typography
                variant="bodyLargeM"
                sx={{
                  mt: '8px',
                  color: COLORS.MAIN_DARK,
                  wordWrap: 'break-word',
                }}
              >
                {value}
              </Typography>
            </Grid>
          ))}
        {props.editMode && renderEditForm()}
      </Grid>
    );
  };

  const handleChange = (value: any) => {
    if (isPhone) {
      setPhoneOtp(value);
    } else {
      setEmailOtp(value);
    }
  };

  const handleSuccessDone = async () => {
    setSuccessModal(false);
    await refetchCheckAuth().unwrap();
    await dispatch(findProfileData());
    navigate(locations.dashboard());
  };

  const renderOtpView = () => (
    <Box component="form" onSubmit={handleSubmitOtp} noValidate sx={{ mt: 1, width: '100%' }}>
      <Typography variant="bodyMedium" sx={{ color: COLORS.MAIN_DARK }} fontWeight={500}>
        {t('enterTheCodeSentToYourMobile')}
      </Typography>
      <Box
        sx={{
          display: 'flex',
          direction: 'row',
          alignItems: 'center',
          justifyContent: 'center',
          height: '56px',
          mt: 4,
          mb: 6,
          '@media (max-width:1050px)': {
            mt: '10px',
          },
        }}
      >
        <OTPInput
          autoFocus
          isArabic={!isEnglish}
          length={OTP_INPUT_LENGTH}
          clearOTP={otpSendTime !== 0}
          className="otpContainer"
          inputClassName="otpInput singleInput"
          onChangeOTP={(v) => handleChange(v)}
          style={{ height: '100%' }}
          // isNumberInput // adding isNumberInput make input field doesn't accept arabic numbers
          inputStyle={{
            borderStyle: 'solid',
            borderWidth: '1px',
            borderColor: COLORS.XLIGHT_GRAY,
            borderRadius: '8px',
            height: '60px',
            width: '60px',
          }}
        />
      </Box>
      <Typography
        component="h5"
        variant="body2"
        ref={errRef}
        className="errmsg"
        aria-live="assertive"
        color="error"
        style={{ textAlign: 'center' }}
      >
        {t(customErrors?.emailOtp) || t(customErrors?.phoneOtp)}
      </Typography>
      <SubmitButton />
      <CountDown otpSendTime={otpSendTime} setOtpSendTime={setOtpSendTime} resendOTP={resendOTP} />
    </Box>
  );

  return (
    <Grid
      item
      container
      display="flex"
      sx={{
        p: '32px',
        boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.05)',
        borderRadius: '12px',
      }}
      component={Paper}
      elevation={0}
    >
      {renderContent()}
      <Modal
        open={openModalOtp}
        onClose={() => setOpenModalOtp(false)}
        aria-labelledby="otp-modal-title"
        aria-describedby="otp-modal-description"
      >
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: {
            xs: '100%',
            sm: '55%',
            md: '45%',
            lg: '35%',
          },
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 10,
          borderRadius: 2,
        }}
        >
          {renderOtpView()}
        </Box>
      </Modal>
      <ChangeEmailAndMobileSucessModal isPhone={isPhone} onSuccessButtonClick={handleSuccessDone} openSuccessfulDialog={successModal} />
    </Grid>
  );
});

export default ProfileDataSection;
