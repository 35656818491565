// eslint-disable-next-line import/no-cycle
import { ProductFile } from '../../types/product';
import type { Logout } from './authTypes';
import type { Asset } from './portfolioTypes';

export const GET_INDEX_FUND_REQUEST = 'GET_INDEX_FUND_REQUEST';
export const GET_INDEX_FUND_SUCCESS = 'GET_INDEX_FUND_SUCCESS';
export const GET_INDEX_FUND_FAIL = 'GET_INDEX_FUND_FAIL';
export const GET_USER_INDEX_FUND_REQUEST = 'GET_USER_INDEX_FUND_REQUEST';
export const GET_USER_INDEX_FUND_SUCCESS = 'GET_USER_INDEX_FUND_SUCCESS';
export const GET_USER_INDEX_FUND_FAIL = 'GET_USER_INDEX_FUND_FAIL';
export const GET_FUND_CHART_DATA_REQUEST = 'GET_FUND_CHART_DATA_REQUEST';
export const GET_FUND_CHART_DATA_SUCCESS = 'GET_FUND_CHART_DATA_SUCCESS';
export const GET_FUND_CHART_DATA_FAIL = 'GET_FUND_CHART_DATA_FAIL';
export const CLEAR_INDEX_FUND = 'CLEAR_INDEX_FUND';

export enum FundRiskLevel {
  LOW = 'Low',
  MEDIUM = 'Medium',
  HIGH = 'High',
  LOW_TO_MEDIUM = 'Low To Medium',
  MEDIUM_TO_HIGH = 'Medium To High',
}

export interface FundRiskLevelOption {
  id: number;
  name: FundRiskLevel;
}

export enum FundCategory {
  MUTUAL_FUND = 'Mutual Fund',
  PRIVATE_FUND = 'Private Fund',
  ETF = 'ETF',
}

export interface FundCategoryOption {
  id: number;
  name: FundCategory;
}

export enum FundStatus {
  ACTIVE = 'Active',
  INACTIVE = 'Inactive',
}

export interface FundStatusOption {
  id: number;
  name: FundStatus;
}

export enum FundType {
  OPEN_ENDED_FUND = 'Open-Ended-Fund',
  CLOSE_ENDED_FUND = 'Close-Ended-Fund',
}

export interface FundTypeOption {
  id: number;
  name: FundType;
}

export interface FundCurrencyOption {
  id: number;
  name: string;
}

export interface FundAttachment {
  id: number;
  documentLink: string;
}

export type Benchmark = {
  id: number;
  nameEn: string;
  nameAr: string;
  createdAt: Date | string;
  updatedAt: Date | string;
};

export type FundGeographicalFocus = {
  id: number;
  nameEn: string;
  nameAr: string;
  createdAt: Date | string;
  updatedAt: Date | string;
};

export type FundManager = {
  id: number;
  nameEn: string;
  nameAr: string;
  descriptionEn: string;
  descriptionAr: string;
  locationEn: string;
  locationAr: string;
  createdAt: Date | string;
  updatedAt: Date | string;
};

export type AssetClass = {
  id: number;
  nameEn: string;
  nameAr: string;
  descriptionEn: string;
  descriptionAr: string;
  createdAt: Date | string;
  updatedAt: Date | string;
};

export enum FundSource {
  YAHOO_FINANCE = 'Yahoo Finance',
  ALPACA_MARKET = 'Alpaca Market',
}

export interface IFundSource {
  id: number;
  name: FundSource;
}

export type FundValuationDay = {
  id: number;
  nameEn: string;
  nameAr: string;
  descriptionEn: string;
  descriptionAr: string;
  expression: string;
  createdAt: Date | string;
  updatedAt: Date | string;
};

export type FundValuationFrequency = {
  id: number;
  nameEn: string;
  nameAr: string;
  descriptionEn: string;
  descriptionAr: string;
  expression: string;
  createdAt: Date | string;
  updatedAt: Date | string;
};

export type IndexFundStrategy = 'Active' | 'Passive';

export interface GicsOption {
  id: number;
  nameEn: string;
  nameAr: string;
  code: string;
}

export interface FundGics {
  id?: number;
  gics: GicsOption;
  weight: number;
}

export type IndexFund = {
  id: number;
  bloombergTicker: string;
  code: string;
  nameEn: string;
  nameAr: string;
  secondNameEn: string;
  secondNameAr: string;
  asset: Asset;
  source: IFundSource;
  assetClass: AssetClass;
  manager: FundManager;
  gics: FundGics[];
  objectiveEn: string;
  objectiveAr: string;
  geographicalFocus: FundGeographicalFocus;
  shariaaComplaint: boolean;
  expenseRatio: number;
  expectedReturn: number;
  valuationDay: FundValuationDay;
  valuationFrequency: FundValuationFrequency;
  type: FundTypeOption;
  category: FundCategoryOption;
  currency: FundCurrencyOption;
  status: FundStatusOption;
  netAssetValuePerUnit: number;
  assetUnderManagement: number;
  inceptionDate: Date;
  managementFee: number;
  minimumSubscription: number;
  minimumRedemption: number;
  subscriptionFee: number;
  redemptionFee: number;
  otherFee: number;
  benchmark: Benchmark;
  topHoldings: string;
  topHoldingsAr: string;
  numberHoldings: number;
  riskLevel: FundRiskLevelOption;
  y1mReturn: number;
  y3mReturn: number;
  y5mReturn: number;
  y1mStdev: number;
  y3mStdev: number;
  y5mStdev: number;
  y1mReturnRisk: number;
  y3mReturnRisk: number;
  y5mReturnRisk: number;
  attachments: FundAttachment[] | ProductFile[];
  strategy: IndexFundStrategy;
  createdAt: Date;
  updatedAt: Date;
};

export type IndexFundSateType = {
  data: IndexFund | null;
  error: string;
  isLoading: boolean;
  isErrorExist: boolean;
};

export type FundChartDataItem = {
  id: number;
  date: string;
  fundNav: number;
  benchmarkNav: number;
  fundResult: number;
  benchmarkResult: number;
  benchmark: Benchmark;
  indexFund: IndexFund;
  createdAt: Date;
  updatedAt: Date;
};

export type FundChartData = {
  data: FundChartDataItem[];
  error: string;
  isLoading: boolean;
  isErrorExist: boolean;
};

export type UserIndexFund = {
  id: number;
  userId: number;
  indexFund: IndexFund;
  indexFundId: number;
  numOfUnits: number;
  createdAt: Date;
  updatedAt: Date;
};

export type UserIndexFundSateType = {
  data: UserIndexFund[];
  error: string;
  isLoading: boolean;
  isErrorExist: boolean;
};

export interface RequestGetIndexFund {
  type: typeof GET_INDEX_FUND_REQUEST;
}

export interface RequestGetIndexFundSuccess {
  type: typeof GET_INDEX_FUND_SUCCESS;
  payload: IndexFund;
}

export interface RequestGetIndexFundFail {
  type: typeof GET_INDEX_FUND_FAIL;
  payload: string;
}

export interface RequestGetUserIndexFunds {
  type: typeof GET_USER_INDEX_FUND_REQUEST;
}

export interface RequestGetUserIndexFundsSuccess {
  type: typeof GET_USER_INDEX_FUND_SUCCESS;
  payload: UserIndexFund[];
}

export interface RequestGetUserIndexFundsFail {
  type: typeof GET_USER_INDEX_FUND_FAIL;
  payload: string;
}

export interface RequestGetFundChartData {
  type: typeof GET_FUND_CHART_DATA_REQUEST;
}

export interface RequestGetFundChartDataSuccess {
  type: typeof GET_FUND_CHART_DATA_SUCCESS;
  payload: FundChartDataItem[];
}

export interface RequestGetFundChartDataFail {
  type: typeof GET_FUND_CHART_DATA_FAIL;
  payload: string;
}

export interface ClearIndexFund {
  type: typeof CLEAR_INDEX_FUND;
}

export type IndexFundAction =
  | RequestGetIndexFund
  | RequestGetIndexFundSuccess
  | RequestGetIndexFundFail
  | RequestGetFundChartData
  | RequestGetFundChartDataSuccess
  | RequestGetFundChartDataFail
  | RequestGetUserIndexFunds
  | RequestGetUserIndexFundsSuccess
  | RequestGetUserIndexFundsFail
  | Logout
  | ClearIndexFund;

export type IndexFundState = Readonly<{
  indexFund: IndexFundSateType;
  userIndexFunds: UserIndexFundSateType;
  fundChartData: FundChartData;
}>;

export enum FundAttachmentType {
  FACT_SHEET = 'Fact Sheet',
  FACT_SHEET_AR = 'Fact Sheet Ar',
}
