import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, RouteObject, useLocation, useRoutes } from 'react-router-dom';
import usePageViews from '../helpers/hooks/usePageViews';
import AppPageLayout from '../layouts/AppPageLayout';
import PublicPageLayout from '../layouts/PublicPageLayout';
import HomePageLayout from '../layouts/HomePageLayout';
import AlpacaActivities from '../pages/user/AlpacaActivities';
import Login from '../pages/user/Login';
import Signup from '../pages/user/Signup';
import Dashboard from '../pages/user/Dashboard';
import ProductDashboard from '../pages/user/ProductDashboard';
import Survey from '../pages/user/Survey';
import RiskSurvey from '../pages/user/RiskSurvey';
import PortfolioDetails from '../pages/user/PortfolioDetails';
import MyWallet from '../pages/user/MyWallet';
import AddEditBankAccount from '../pages/user/AddEditBankAccount';
import Docs from '../pages/user/Docs';
import Home from '../pages/user/Home';
import { authSelector } from '../redux/features/auth/auth.selectors';
import { locations } from './locations';
import Orders from '../pages/user/Orders';
import Invest from '../pages/user/Invest';
import TransactionsHistory from '../pages/user/TransactionsHistory';
import { Error } from '../pages/user/Error';
import ResetPassword from '../pages/user/ResetPassword';
import Faqs from '../pages/user/Faqs';
import Education from '../pages/user/Education';
import UserProfile from '../pages/user/UserProfile';
import MyGoals from '../pages/user/MyGoals';
import Reports from '../pages/user/Reports';
import Tutorials from '../pages/user/Tutorials';
import About from '../pages/user/About';
import InviteFriend from '../pages/user/InviteFriend';
import NafazData from '../pages/user/NafazData';
import FundDetails from '../pages/user/FundDetails';
import Redemption from '../pages/user/Redemption';
import { TermsAndConditions } from '../pages/user/TermsAndConditions';
import { AccountOpeningAgreement } from '../pages/user/AccountOpeningAgreement';
import AskForAnAdvice from '../pages/user/AskForAnAdvice';
import { EmailVerification } from '../pages/user/EmailVerification';
import Products from '../pages/user/Products';
import CustomProduct from '../pages/user/CustomProduct';
import NafazAuthentication from '../components/User/NafazData/NafazAuthentication';
import NafazVerification from '../components/User/Verification/NafazVerfication';

interface AppRoutesProps {
  isArabic: boolean;
}

export function AppRoutes({ isArabic }: AppRoutesProps) {
  const { pathname, hash, key, state } = useLocation();
  const { isLoggedIn } = useSelector(authSelector);

  usePageViews();

  useEffect(() => {
    // if not a hash link, scroll to top
    if (hash === '') {
      window.scrollTo(0, 0);
    } else {
      // else scroll to id
      setTimeout(() => {
        const id = hash.replace('#', '');
        const element = document.getElementById(id);

        if (element) {
          element.scrollIntoView();
        }
      }, 0);
    }
  }, [pathname, hash, key]); // do this on route change

  const loggedInBasedHomeRoutes: RouteObject[] = [
    {
      path: locations.termsAndConditions(),
      element: <TermsAndConditions />,
    },
    {
      path: locations.emailVerification(),
      element: <EmailVerification />,
    },
  ];

  const loggedInBasedAppRoutes: RouteObject[] = [];

  return useRoutes([
    {
      path: locations.home(),
      element: <HomePageLayout />,
      children: [
        {
          // index: true,
          path: locations.home(),
          element: <Home />,
        },
        {
          path: locations.survey(),
          element: <Survey />,
        },
        {
          index: true,
          path: locations.products(),
          element: <Products />,
        },
        // {
        //   path: locations.customProduct(),
        //   element: <CustomProduct />,
        // },
        {
          path: locations.notFound(),
          element: <Error />,
        },
        {
          path: locations.riskSurvey(),
          element: <RiskSurvey />,
        },
        {
          path: locations.docs(),
          element: <Docs />,
        },
        {
          path: locations.nafazData(),
          element: <NafazData />,
        },
        // {
        //   path: locations.accountOpeningAgreement(),
        //   element: <AccountOpeningAgreement />,
        // },
        {
          path: locations.nafazAuthentication(),
          element: <NafazAuthentication />,
        },
        {
          path: locations.nafazVerification(),
          element: <NafazVerification />,
        },
        ...(isLoggedIn ? loggedInBasedHomeRoutes : []),
      ],
    },
    {
      path: locations.home(),
      element: <AppPageLayout />,
      children: [
        {
          index: true,
          path: locations.dashboard(),
          element: <Dashboard beta />,
        },
        {
          path: locations.oldDashboard(),
          element: <Dashboard />,
        },
        // {
        //   path: locations.riskSurvey(),
        //   element: <RiskSurvey />,
        // },
        // {
        //   path: locations.products(),
        //   element: <Products />,
        // },
        {
          path: locations.customProduct(),
          element: <CustomProduct />,
        },
        // {
        //   path: locations.survey(),
        //   element: <Survey />,
        // },
        {
          path: locations.accountOpeningAgreement(),
          element: <AccountOpeningAgreement />,
        },
        {
          path: locations.productDashboard(),
          element: <ProductDashboard />,
        },
        {
          path: locations.portfolioDetails(),
          element: <PortfolioDetails />,
        },
        {
          path: locations.redemption(),
          element: <Redemption />,
        },
        {
          path: locations.fundDetails(),
          element: <FundDetails />,
        },
        {
          path: locations.invest(),
          element: <Invest />,
        },
        {
          path: locations.transactionsHistory(),
          element: <TransactionsHistory />,
        },
        {
          path: locations.orders(),
          element: <Orders />,
        },
        {
          path: locations.myWallet(),
          element: <MyWallet />,
        },
        {
          path: locations.myGoals(),
          element: <MyGoals />,
        },
        {
          path: locations.reports(),
          element: <Reports />,
        },
        {
          path: locations.tutorials(),
          element: <Tutorials />,
        },
        {
          path: locations.askForAnAdvise(),
          element: <AskForAnAdvice />,
        },
        {
          path: locations.inviteFriend(),
          element: <InviteFriend />,
        },
        {
          path: locations.faqs(),
          element: <Faqs />,
        },
        {
          path: locations.about(),
          element: <About />,
        },
        {
          path: locations.education(),
          element: <Education />,
        },
        {
          path: locations.myProfile(),
          element: <UserProfile />,
        },
        {
          path: locations.myWalletBankAccount(),
          element: <AddEditBankAccount />,
        },
        {
          path: locations.alpacaActivities(),
          element: <AlpacaActivities />,
        },
        ...(isLoggedIn ? loggedInBasedAppRoutes : []),
        {
          path: '*',
          element: <Navigate to={locations.notFound()} />,
        },
      ],
    },
    {
      path: locations.home(),
      element: <PublicPageLayout />,
      children: [
        {
          path: locations.login(),
          element: <Login />,
        },
        ...(isLoggedIn ? [] : loggedInBasedHomeRoutes),
        ...(isLoggedIn ? [] : loggedInBasedAppRoutes),
        {
          path: locations.resetPassword(),
          element: <ResetPassword />,
        },
        {
          path: locations.signUp(),
          element: <Signup isArabic={isArabic} />,
        },
        {
          path: '*',
          element: <Navigate to={locations.notFound()} />,
        },
      ],
    },
    {
      path: '*',
      element: <Navigate to={locations.home()} replace state={state} />,
    },
  ]);
}
