import React from 'react';
import { useTranslation } from 'react-i18next';
import Grid from '@mui/material/Grid';
import TutorialCard from './TutorialCard';
import { TUTORIALS } from '../../../constants/tutorials';

export type TutorialViewProps = {
  beta?: boolean;
};

const TutorialView: React.FC<TutorialViewProps> = ({ beta }) => {
  const { i18n } = useTranslation();

  return (
    <Grid container direction="row" justifyContent="flex-start" alignItems="stretch" sx={{ margin: '0 auto' }}>
      {TUTORIALS[i18n.language].map((tutorial: any) => (
        <Grid item xs={12} md={6} key={tutorial.id} sx={{ mb: { xs: '20px !important', sm: '24px !important' } }}>
          <TutorialCard tutorial={tutorial} beta={beta} />
        </Grid>
      ))}
    </Grid>
  );
};

export default TutorialView;
