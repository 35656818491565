import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import React from 'react';
import { addHours, addDays, addWeeks, addMonths, addQuarters, format } from 'date-fns';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTypedDispatch } from '../../../redux/store';
import { fetchCreatedSubscription, saveSubscription } from '../../../store_deprecated/actions/walletActions';
import {
  SaveSubscriptionPayload,
  SaveSubscriptionSuccessPayload,
  SubscriptionItem,
} from '../../../store_deprecated/types/walletTypes';
import { recurringPaymentFormSchema } from '../../../forms/validationSchema/recurringPayment';
import MButton from '../../../shared/MButton';
import {
  FrequencyOptions,
  RecurringEndType,
  RecurringEndTypeOptions,
  RecurringFrequencyUnit,
  RecurringStatus,
  recurringPaymentFormFields,
} from '../../../forms/fields/recurringPayment';
import COLORS from '../../../constants/colors';
import { formatValue } from '../../../helpers/string';
import { ar2En } from '../../../utils/number';

type AddRecurrentBillingFormProps = {
  onCloseRecurringSettings?: () => void;
  onSubscriptionSubmit: (isSubscriptionSubmitted: boolean) => void;
};

const AddRecurrentBillingForm: React.FC<AddRecurrentBillingFormProps> = ({
  onCloseRecurringSettings,
  onSubscriptionSubmit,
}: AddRecurrentBillingFormProps) => {
  const { t, i18n } = useTranslation();
  const { t: tApiError } = useTranslation('apiError');

  const dispatch = useTypedDispatch();

  const [createdSubscription, setCreatedSubscription] = React.useState<SubscriptionItem | null>(null);
  const isDevelopment = process.env.REACT_APP_ENVIRONMENT === 'development';
  const allowedFrequencyOptions = isDevelopment
    ? FrequencyOptions
    : [RecurringFrequencyUnit.WEEKLY, RecurringFrequencyUnit.MONTHLY, RecurringFrequencyUnit.QUARTERLY];

  const errRef = React.useRef<any>();
  const [errorMessage, setErrorMessage] = React.useState('');

  const isEnglish = i18n.language === 'en';
  const xs = useMediaQuery('(max-width:600px)');

  const storedAmount = Number(sessionStorage.getItem('lastPaymentAmount')) || 0;

  React.useEffect(() => {
    const prefetchData = async () => {
      const result: SubscriptionItem | null = (await dispatch(fetchCreatedSubscription())) as any;

      setCreatedSubscription(result);
    };

    prefetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const upsertSubscription = async (payload: SaveSubscriptionPayload) => {
    try {
      const result: SaveSubscriptionSuccessPayload = (await dispatch(saveSubscription(payload))) as any;

      if (result.id) {
        sessionStorage.setItem('subscriptionId', `${result.id}`);
      }
    } catch (e: any) {
      setErrorMessage(e.message);
    }
  };

  const { handleSubmit, handleChange, values, isValid, setFieldValue, touched, errors, getFieldProps } = useFormik({
    initialValues: {
      [recurringPaymentFormFields.amount]: storedAmount,
      [recurringPaymentFormFields.frequencyUnit]: RecurringFrequencyUnit.MONTHLY,
      [recurringPaymentFormFields.startDate]: new Date(),
      [recurringPaymentFormFields.endDateType]: RecurringEndType.DATE,
      [recurringPaymentFormFields.endDate]: addMonths(new Date(), 6),
    },
    validateOnMount: true,
    validationSchema: recurringPaymentFormSchema,
    onSubmit: async (formValues) => {
      let nextDueDay: Date;
      switch (formValues.frequencyUnit) {
        case RecurringFrequencyUnit.HOURLY:
          nextDueDay = addHours(formValues.startDate, 1);
          break;
        case RecurringFrequencyUnit.DAILY:
          nextDueDay = addDays(formValues.startDate, 1);
          break;
        case RecurringFrequencyUnit.WEEKLY:
          nextDueDay = addWeeks(formValues.startDate, 1);
          break;
        case RecurringFrequencyUnit.QUARTERLY:
          nextDueDay = addQuarters(formValues.startDate, 1);
          break;
        default:
          nextDueDay = addMonths(formValues.startDate, 1);
      }
      const payload: SaveSubscriptionPayload = {
        id: createdSubscription?.id || undefined,
        endDate: formValues.endDateType === RecurringEndType.INFINITY ? null : format(formValues.endDate, 'yyyy-MM-dd'),
        startDate: format(formValues.startDate, 'yyyy-MM-dd'),
        frequencyUnit: formValues.frequencyUnit,
        endDateType: formValues.endDateType,
        amount: formValues.amount,
        dueDate: nextDueDay,
        paymentBrand: 'MADA',
        isPrimaryCard: false,
        status: RecurringStatus.CREATED,
      };

      upsertSubscription(payload);
      onSubscriptionSubmit(true);
    },
  });

  const handleDateChange = (field: string, date: string | Date | null) => {
    setFieldValue(field, date);
  };

  React.useEffect(() => {
    if (values[recurringPaymentFormFields.endDateType] === RecurringEndType.INFINITY) {
      setFieldValue(recurringPaymentFormFields.endDate, null);
    } else {
      setFieldValue(recurringPaymentFormFields.endDate, addMonths(values[recurringPaymentFormFields.startDate], 6));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    // eslint-disable-next-line react-hooks/exhaustive-deps
    values[recurringPaymentFormFields.endDateType],
  ]);

  const parseValue = (v: string | number) =>
    `${v}`
      // eslint-disable-next-line no-useless-escape
      .replace(/([\.\-\,])(?=.*\1)/g, '')
      // eslint-disable-next-line no-useless-escape
      .replace(/[^.\-\d]/g, '');

  const handleFieldChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    field: string,
    hasDecimal = true,
  ) => {
    const initialValue = hasDecimal ? event.target.value : event.target.value.split('.')[0];

    const newValue: string = parseValue(ar2En(initialValue));
    const newValueNumber = +newValue;
    if (Number.isNaN(newValueNumber) || newValue.endsWith('.') || !newValue) {
      event.target.value = newValue;
      setFieldValue(field, event.target.value);
      return;
    }
    event.target.value = `${newValueNumber}`;
    setFieldValue(field, event.target.value);
  };

  return (
    <Grid component="form" padding="0px 24px">
      <Grid item xs={12}>
        <FormControl fullWidth>
          <Box marginBottom={5}>
            <Typography
              variant={isEnglish ? 'bodyMedium' : 'cairoM'}
              color={COLORS.MAIN_DARK}
              textAlign="justify"
              paragraph
              sx={{
                marginTop: 4,
                fontSize: { xs: '0.875rem', md: '1rem' },
              }}
            >
              {t('recurringForm.amount')}
            </Typography>
            <TextField
              id="amount"
              variant="outlined"
              error={touched.amount && !!errors.amount}
              helperText={touched.amount && t(errors.amount as string)}
              {...getFieldProps(recurringPaymentFormFields.amount)}
              value={formatValue(!isEnglish, (values[recurringPaymentFormFields.amount] || '').toString()).replace(
                /٬/g,
                ',',
              )}
              name={recurringPaymentFormFields.amount}
              required
              sx={{
                borderRadius: '8px',
                '& .MuiFormHelperText-root': {
                  fontFamily: isEnglish ? 'Montserrat' : 'Noto Kufi Arabic',
                },
                '& .MuiFormLabel-root.Mui-error': {
                  backgroundColor: 'transparent !important',
                },
                '& .MuiInput-underline.Mui-error:after': {
                  borderBottomColor: 'transparent !important',
                },
                '& .MuiFormHelperText-root.Mui-error': {
                  backgroundColor: 'transparent !important',
                },
                width: xs ? '100%' : '100%',
              }}
              InputProps={{
                required: true,
                type: 'text',
                style: {
                  fontFamily: isEnglish ? 'Montserrat' : 'Noto Kufi Arabic',
                  backgroundColor: 'white',
                },
              }}
              placeholder={t('recurringForm.amountPlaceholder')}
              type="text"
              onChange={(e) => handleFieldChange(e, recurringPaymentFormFields.amount)}
            />
          </Box>
        </FormControl>
      </Grid>

      <Grid xs={12}>
        <Typography
          variant={isEnglish ? 'bodyMedium' : 'cairoM'}
          color={COLORS.MAIN_DARK}
          textAlign="justify"
          paragraph
          sx={{
            fontSize: { xs: '0.875rem', md: '1rem' },
            marginBottom: '0px',
          }}
        >
          {t('recurringForm.frequencyUnit')}
        </Typography>
        <RadioGroup
          {...getFieldProps(recurringPaymentFormFields.frequencyUnit)}
          name={recurringPaymentFormFields.frequencyUnit}
          row={!xs}
          sx={{
            justifyContent: 'center',
            width: '100%',
          }}
        >
          <Grid container spacing={2} xs={12}>
            {allowedFrequencyOptions.map((option) => (
              <Grid item xs={12} sm={4} key={option}>
                <FormControlLabel
                  id={`bank-type-${option}`}
                  value={option}
                  control={
                    <Radio
                      sx={{
                        color: COLORS.SECONDARY_GREEN,
                        '&.Mui-checked': {
                          color: COLORS.SECONDARY_GREEN,
                        },
                      }}
                    />
                  }
                  label={
                    <Typography
                      variant="h6"
                      // sx={{
                      //   fontSize: 'clamp(1rem, 6vw + 1rem, 1.125rem)',
                      //   color:
                      //     values[recurringPaymentFormFields.frequencyUnit] === option
                      //       ? COLORS.SECONDARY_GREEN
                      //       : COLORS.MAIN_DARK,
                      // }}
                      textAlign="left"
                    >
                      {t(`recurringForm.${option}`)}
                    </Typography>
                  }
                  sx={{
                    border: `1px solid ${COLORS.XX_LIGHT_GREEN}`,
                    borderRadius: 1,
                    margin: 1,
                    padding: '4px 3px',
                    width: '100%',
                  }}
                />
              </Grid>
            ))}
          </Grid>
        </RadioGroup>
      </Grid>

      <Grid xs={12} marginTop={3}>
        <FormControl
          sx={{
            width: '100%',
            display: 'flex',
          }}
          style={{
            flexDirection: 'column',
          }}
          variant="outlined"
        >
          <Box>
            <Typography
              variant={isEnglish ? 'bodyMedium' : 'cairoM'}
              color={COLORS.MAIN_DARK}
              textAlign="justify"
              paragraph
              sx={{ marginBottom: 1 }}
            >
              {t('recurringForm.startDate')}
            </Typography>
          </Box>
          <Box>
            <DatePicker
              sx={{
                width: '100%',
              }}
              {...getFieldProps(recurringPaymentFormFields.startDate)}
              value={values[recurringPaymentFormFields.startDate]}
              onChange={(value) => handleDateChange(recurringPaymentFormFields.startDate, value)}
              maxDate={
                values[recurringPaymentFormFields.endDateType] === RecurringEndType.INFINITY
                  ? null
                  : addDays(values[recurringPaymentFormFields.endDate], -1)
              }
              minDate={new Date()}
              slotProps={{
                textField: {
                  autoFocus: true,
                  id: 'dateFilterFrom',
                  name: 'dateFilterFrom',
                  helperText: t(errors.startDate as string),
                },
              }}
            />
          </Box>
        </FormControl>
      </Grid>

      <Grid container spacing={3} sx={{ marginTop: 2 }}>
        <Grid item xs={12}>
          <Typography
            variant={isEnglish ? 'bodyMedium' : 'cairoM'}
            color={COLORS.MAIN_DARK}
            textAlign="justify"
            paragraph
            sx={{
              marginBottom: '0px',
            }}
          >
            {t('recurringForm.endDate')}
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <DatePicker
            {...getFieldProps(recurringPaymentFormFields.endDate)}
            disabled={values[recurringPaymentFormFields.endDateType] === RecurringEndType.INFINITY}
            value={values[recurringPaymentFormFields.endDate]}
            onChange={(value) => handleDateChange(recurringPaymentFormFields.endDate, value || null)}
            minDate={addDays(values[recurringPaymentFormFields.startDate], 1)}
            sx={{ width: '100%' }}
            slotProps={{
              textField: {
                autoFocus: true,
                id: 'dateFilterFrom',
                name: 'dateFilterFrom',
                helperText: t(errors.endDate as string),
              },
            }}
          />
        </Grid>

        <Grid item xs={12} container spacing={2} marginTop="3px">
          <RadioGroup
            row
            {...getFieldProps(recurringPaymentFormFields.endDateType)}
            name={recurringPaymentFormFields.endDateType}
            sx={{ width: '100%' }}
          >
            {RecurringEndTypeOptions.map((option) => (
              <Grid
                item
                xs={12}
                sm={6}
                md={6}
                key={option}
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  '& .MuiFormControlLabel-root': {
                    width: { xs: '100%', sm: '90%' },
                  },
                }}
              >
                <FormControlLabel
                  key={option}
                  id={`bank-type-${option}`}
                  value={option}
                  control={
                    <Radio sx={{ color: COLORS.SECONDARY_GREEN, '&.Mui-checked': { color: COLORS.SECONDARY_GREEN } }} />
                  }
                  label={
                    <Typography variant="h6" textAlign="left">
                      {t(`recurringForm.${option}`)}
                    </Typography>
                  }
                  sx={{
                    border: `1px solid ${COLORS.XX_LIGHT_GREEN}`,
                    borderRadius: 1,
                    marginBottom: 2,
                    width: '100%',
                    padding: '4px 3px',
                    marginLeft: '16px',
                  }}
                />
              </Grid>
            ))}
          </RadioGroup>
        </Grid>
      </Grid>

      <Grid item>
        <Typography
          component="h5"
          variant="body2"
          ref={errRef}
          className={errorMessage ? 'errmsg' : 'offscreen'}
          aria-live="assertive"
          color="error"
          sx={{ mb: 4 }}
        >
          {tApiError(errorMessage)}
        </Typography>
      </Grid>
      <Grid item container columnSpacing="24px" mt="48px">
        <Grid item xs={12} sx={{ mb: { xs: '12px', md: 'unset' } }}>
          <MButton
            fullWidth
            variant="contained"
            onClick={() => handleSubmit()}
            disabled={!isValid}
            id="NextRecurringSettingsButton"
            buttonProps={{
              sx: {
                width: { xs: '100%', md: '100%' },
                height: { xs: '50px', md: 60 },
                borderRadius: '80px',
                boxShadow: 'none',
                backgroundColor: COLORS?.X_DARK_BLUE,
              },
            }}
          >
            {t('next')}
          </MButton>
        </Grid>
        <Grid item xs={12} sx={{ mb: { xs: '12px', md: 'unset' }, mt: '16px' }}>
          <MButton
            fullWidth
            variant="text"
            onClick={onCloseRecurringSettings}
            id="CancelRecurringSettingsButton"
            buttonProps={{
              sx: {
                width: { xs: '100%', md: '100%' },
                height: { xs: '50px', md: 60 },
                borderRadius: '80px',
                boxShadow: 'none',
              },
            }}
          >
            {t('cancel')}
          </MButton>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AddRecurrentBillingForm;
