import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import COLORS from '../../../constants/colors';
import {
  useFindProfileDataQuery,
} from '../../../redux/features/auth/auth.apiSlice';
import { authSelector } from '../../../redux/features/auth/auth.selectors';
import { fetchUserPreferenceByType } from '../../../redux/modules/userPreference/userPreference.actions';
import { locations } from '../../../routes/locations';
import Loader from '../../../shared/Loader';
import { useTypedDispatch } from '../../../redux/store';
import { findProfileData } from '../../../store_deprecated/actions/authActions';
import {
  findPortfolioLatestStatistics,
} from '../../../store_deprecated/actions/portfolioActions';
import { fetchLastSurveyResult } from '../../../store_deprecated/actions/surveyActions';
import { fetchBankInfo } from '../../../store_deprecated/actions/walletActions';
import { UserPreferenceTypeEnum } from '../../../types/userPreference';
import ModeratePortfolio from './ModeratePortfolio';
import PortfolioPending from '../Dashboard/PortfolioPending';
import { useFetchSubscribedProductsQuery } from '../../../redux/features/products/products.apiSlice';
import { Product } from '../../../types/product';
import { SubscribedPortfolioStatus } from '../../../store_deprecated/types/portfolioTypes';

const DetailedProductDashboardView: React.FC = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useTypedDispatch();
  const navigate = useNavigate();
  const isEnglish = i18n.language === 'en';
  const { state: routerState } = useLocation();
  const productId = routerState?.productId;

  const [initialized, setInitialized] = useState(false);
  const { user } = useSelector(authSelector); // TODO DONE

  const [targetProduct, setTargetProduct] = useState<Product | null>(null);
  const { data } = useFindProfileDataQuery();

  const lastUpdated = moment(user?.updatedAt)
    .locale(i18n.language)
    .format('hh:mm A - MMM Do YYYY');

  const {
    data: subscribedProducts,
    isLoading: isFetchSubscribedProductsLoading,
    isError: isFetchSubscribedProductsFailed,
  } = useFetchSubscribedProductsQuery({});

  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        await Promise.all([
          dispatch(fetchUserPreferenceByType(UserPreferenceTypeEnum.RECOMMENDED_PORTFOLIO)),
          dispatch(findProfileData()),
          dispatch(fetchBankInfo()),
          dispatch(findPortfolioLatestStatistics()),
          dispatch(fetchLastSurveyResult()),
        ]);
      } catch (e) {
        console.log('Some error');
      }
      setInitialized(true);
    };

    fetchInitialData();

    const matchMdAndAboveView = window.matchMedia('(min-width: 600px)');

    document.getElementsByTagName('main')[0].getElementsByTagName('div')[0].style.display = matchMdAndAboveView.matches
      ? 'block'
      : 'none';
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (productId) {
      const theProduct = subscribedProducts?.items.filter((item) => item.id === productId);
      setTargetProduct(theProduct ? theProduct[0] : null);
    } else navigate(locations.dashboard());
  }, [subscribedProducts, productId]);

  if (!initialized || isFetchSubscribedProductsLoading) {
    return <Loader />;
  }

  if ((targetProduct as any)?.status?.name !== SubscribedPortfolioStatus.ACCEPTED) {
    return <PortfolioPending />;
  }

  return (
    <Box sx={{ padding: { xs: '0 32px', md: '32px 32px 0 32px' } }} maxWidth="100%">
      <Grid container display="flex" flexDirection="column">
        <Grid item container display="flex" flexDirection="column">
          <Typography
            variant="h6"
            id="welcomeHeader"
            sx={{
              textTransform: 'capitalize',
              fontWeight: 500,
              fontSize: '20px',
              lineHeight: '28px',
              color: COLORS.PRIMARY_BLUE,
            }}
          >
            {t('dashboard.welcome')}
          </Typography>
          <Typography
            variant="h1"
            sx={{
              fontWeight: 600,
              fontSize: { xs: '20px', md: '36px' },
              lineHeight: '44px',
              color: COLORS.MAIN_DARK,
            }}
          >
            {(isEnglish ? data?.fullNameEn : data?.fullNameAr) || user?.fullName}
          </Typography>
          <Typography
            variant="bodySmall"
            sx={{
              fontWeight: 400,
              fontSize: { xs: '14px', md: '16px' },
              lineHeight: '24px',
              color: COLORS.LIGHT_GRAY,
            }}
          >
            {t('dashboard.lastUpdated')}:&nbsp;{lastUpdated}
          </Typography>
        </Grid>

        <Grid item sx={{ marginTop: '28px' }} maxWidth="100%">
          {targetProduct?.id && <ModeratePortfolio subscribedProduct={targetProduct} />}
        </Grid>
      </Grid>
    </Box>
  );
};

export default DetailedProductDashboardView;
