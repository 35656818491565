import React from 'react';

import './ProductWeight.scss';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import MadkholSlider from '../Slider/Slider';
import { PortfolioAssociatedIndexFund } from '../../../../store_deprecated/types/portfolioTypes';
import { locations } from '../../../../routes/locations';
import { formatNumber } from '../../../../utils/number';

interface ProductWeightProps {
  fund: PortfolioAssociatedIndexFund;
  onChange?: (value: PortfolioAssociatedIndexFund) => void;
  color?: string;
  showThumb?: boolean;
  max?: number;
  min?: number;
  editable?: boolean;
}

const ProductWeight: React.FC<ProductWeightProps> = ({
  fund,
  onChange,
  max = 100,
  min = 0,
  color,
  editable = false,
  showThumb,
}) => {
  const { t, i18n } = useTranslation();
  const isEnglish = i18n.language === 'en';
  const navigate = useNavigate();

  const onWeightChange = (value: number) => {
    if (!onChange) {
      console.warn('[ProductWeight]: prop onChange not set.');
      return;
    }
    onChange({ ...fund, weight: value });
  };

  return (
    <div className="product-weight">
      <div className="position">
        <Typography
          onClick={() => navigate(locations.fundDetails(fund?.indexFund?.id))}
          sx={{ cursor: 'pointer', color: `${color}` }}
          fontWeight="bold"
        >
          {isEnglish ? fund?.indexFund?.nameEn : fund?.indexFund?.nameAr}
        </Typography>
        <Typography fontWeight="bold" sx={{ color: `${color}` }}>
          {formatNumber(Number(fund.weight) || 0, i18n.language, 0)}%
        </Typography>
      </div>
      <MadkholSlider
        value={fund?.weight}
        onChange={onWeightChange}
        max={max}
        min={min}
        disabled={!editable}
        color={color}
        showThumb={showThumb}
      />
    </div>
  );
};

export default ProductWeight;
