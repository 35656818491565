import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocation, useSearchParams } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';

import Deposit, { DepositProps } from '../../components/User/MyWallet/Deposit';
// import ApplePay from "../../components/User/MyWallet/ApplePay";
import Widgets from '../../components/User/MyWallet/Widgets';
import IbanNumber from '../../components/User/MyWallet/IbanNumber';
import BankAccounts from '../../components/User/MyWallet/BankAccounts';
import { UserStatus } from '../../constants/user';
import { authSelector } from '../../redux/features/auth/auth.selectors';
import { useTypedDispatch } from '../../redux/store';
import {
  clearWallet,
  fetchAnbMasterardConfigAction,
  fetchBankInfo,
  fetchNetWithdraw,
  fetchStatementDelta,
  queryHyperPayPaymentStatusAction,
  queryHyperPayRegistrationStatusAction,
} from '../../store_deprecated/actions/walletActions';
import SwiftCode from '../../components/User/MyWallet/SwiftCode';
import {
  findPortfolioLatestStatistics,
  findSubscribedPortfolio,
} from '../../store_deprecated/actions/portfolioActions';
import { findUserIndexFunds } from '../../store_deprecated/actions/indexFundActions';
import Withdraw from '../../components/User/MyWallet/Withdraw';
import PaymentSuccessfulScreen from '../../shared/PaymentSuccessfulScreen';
import PaymentFailureScreen from '../../shared/PaymentFailureScreen';
import BetaCards from '../../components/User/MyWallet/BetaCards';
import Notification from '../../components/User/BetaDashboard/Notification';
import NotificationType from '../../constants/NotificationType';
import COLORS from '../../constants/colors';
import { findAlpacaDividends } from '../../store_deprecated/actions/alpacaActions';
import { useFetchSubscribedProductsQuery } from '../../redux/features/products/products.apiSlice';
// import Cards from '../../components/User/MyWallet/Cards';

type HyperPayPaymentStatus = {
  result: {
    code: string;
    description: string;
  };
  buildNumber: string;
  timestamp: string;
  ndc: string;
};

type HyperPayRegestrationStatus = {
  id: string;
  result: {
    code: string;
    description: string;
  };
  card: {
    last4Digits: string;
  };
  buildNumber: string;
  timestamp: string;
  ndc: string;
};

const MyWallet: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useTypedDispatch();
  const { user } = useSelector(authSelector);
  const [depositConfig, setDepositConfig] = useState<DepositProps | null>(null);
  const [successfulDialog, setOpenSuccessfulDialog] = React.useState(false);
  const [failureDialog, setFailureDialog] = React.useState(false);
  const [last4Digits, setLast4Digits] = React.useState('');
  const { state } = useLocation();
  const { data: subscribedProducts } = useFetchSubscribedProductsQuery({});
  const isBankSave = state?.isBankSave;

  const canDeposit = user?.status?.name === UserStatus.ACTIVE;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [searchParams, setSearchParams] = useSearchParams();
  const orderId = searchParams.get('id');
  const resourcePath = searchParams.get('resourcePath');

  const successPattern = /^(000\.000\.|000\.100\.1|000\.[36]|000\.400\.[1][12]0|000\.400\.0[^3]|000\.400\.100)/;
  const pendingPattern = /^(000\.200|800\.400\.5|100\.400\.500)/;

  // TODO: Temporary solution to pass variables between screens, need to use state
  const hyperPayBrand = sessionStorage.getItem('hyperPayBrand') || '';
  const hyperPayPaymentType = sessionStorage.getItem('hyperPayPaymentType') || '';
  const subscriptionId = Number(sessionStorage.getItem('subscriptionId')) || 0;

  const closeDialog = () => {
    window.history.pushState({}, '', window.location.pathname);
    setOpenSuccessfulDialog(false);
    setFailureDialog(false);
    window.location.reload();
  };

  const handleHyperPayPaymentStatus = async (paymentType: 'DB' | 'PA') => {
    try {
      if (resourcePath) {
        const paymentStatus = (await dispatch(
          queryHyperPayPaymentStatusAction({ resourcePath, paymentType, brand: hyperPayBrand, subscriptionId }),
        )) as HyperPayPaymentStatus;

        const match1 = successPattern.test(paymentStatus.result.code);
        const match2 = pendingPattern.test(paymentStatus.result.code);

        return match1 || match2;
      }
      return false;
    } catch (err: any) {
      return false;
    }
  };

  const handleHyperPayRegestrationStatus = async () => {
    try {
      if (resourcePath) {
        const paymentStatus = (await dispatch(
          queryHyperPayRegistrationStatusAction({ resourcePath, paymentType: 'RG', brand: hyperPayBrand }),
        )) as HyperPayRegestrationStatus;
        const match1 = successPattern.test(paymentStatus.result.code);
        const match2 = pendingPattern.test(paymentStatus.result.code);
        const result = match1 || match2;
        if (result) {
          setLast4Digits(paymentStatus.card.last4Digits);
        }
        return result;
      }
      return false;
    } catch (err: any) {
      return false;
    }
  };

  useEffect(() => {
    const fetchPaymentStatus = async () => {
      const isStandalone = hyperPayPaymentType === 'RG';
      const isRecurring = hyperPayPaymentType === 'PA';
      const isSuccess = isStandalone
        ? await handleHyperPayRegestrationStatus()
        : await handleHyperPayPaymentStatus(isRecurring ? 'PA' : 'DB');
      if (isSuccess && orderId) {
        setOpenSuccessfulDialog(true);
      } else {
        setFailureDialog(true);
      }
    };
    if (resourcePath) {
      fetchPaymentStatus();
    }
  }, [orderId, resourcePath]);

  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        const results = await Promise.all([
          dispatch(fetchBankInfo()),
          dispatch(fetchNetWithdraw()),
          dispatch(fetchStatementDelta()),
          dispatch(findUserIndexFunds()),
          dispatch(findSubscribedPortfolio()),
          dispatch(findPortfolioLatestStatistics()),
          dispatch(fetchAnbMasterardConfigAction()),
          dispatch(findAlpacaDividends()),
        ]);

        const anbMastercardConfig = results?.[6];

        if (anbMastercardConfig?.url && anbMastercardConfig?.version) {
          setDepositConfig({
            anbMastercardCheckoutBaseUrl: anbMastercardConfig.url,
            anbMastercardCheckoutVersion: anbMastercardConfig.version,
          });
        }
      } catch (e) {
        console.log('Some error');
      }
    };

    fetchInitialData();

    return () => {
      dispatch(clearWallet());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box
      sx={{
        padding: { xs: '0 24px', md: '32px' },
        mt: { xs: '0', md: 'auto' },
        minHeight: '70vh',
      }}
    >
      <Grid container flexDirection="column" justifyContent="space-between">
        <Grid item>
          <Typography component="h1" variant="h5">
            {t('myWallet')}
          </Typography>
        </Grid>
        <Grid
          item
          mt="32px"
          sx={{
            '@media (max-width:900px)': {
              width: 'fit-content',
            },
          }}
        >
          <Widgets subscribedProducts={subscribedProducts?.items} />
        </Grid>
        {isBankSave && (
          <Notification
            status={NotificationType.SUCCESS}
            padding={{ xs: '24px 0', md: '32px 0' }}
            text={
              <Typography variant="bodyMedium" sx={{ color: COLORS.MAIN_DARK, whiteSpace: 'pre-line' }}>
                {t('savedBankMsg')}
              </Typography>
            }
          />
        )}
        <Grid item container columnSpacing="24px" mt="24px">
          <Grid item xs={12} md={6} sx={{ mb: { xs: '12px', md: 'unset' } }}>
            <IbanNumber />
          </Grid>
          <Grid item xs={12} md={6} sx={{ mb: { xs: '12px', md: 'unset' } }}>
            <SwiftCode />
          </Grid>
        </Grid>
        {canDeposit ? (
          <Grid item container columnSpacing="24px" mt="48px">
            <Grid item xs={12} sx={{ mb: { xs: '12px', md: 'unset' } }}>
              <Deposit {...depositConfig} page="my-wallet" />
            </Grid>
            <Grid item xs={12} sx={{ mb: { xs: '12px', md: 'unset' } }}>
              <Withdraw />
            </Grid>
          </Grid>
        ) : (
          <Box mt="48px">
            <Withdraw />
          </Box>
        )}
        <Box mt="22px" sx={{ width: '100%' }}>
          <BetaCards />
        </Box>
        {/* <Box mt="48px">
          <Cards />
        </Box> */}
        <Grid item mt="48px">
          <BankAccounts />
        </Grid>
      </Grid>
      <Dialog open={successfulDialog} onClose={closeDialog} disableScrollLock>
        <Box sx={{ padding: '32px 24px' }}>
          <PaymentSuccessfulScreen
            onSuccessButtonClick={closeDialog}
            orderId={orderId || ''}
            resourcePath={resourcePath || ''}
            last4Digits={last4Digits || ''}
          />
        </Box>
      </Dialog>
      <Dialog open={failureDialog} onClose={closeDialog} disableScrollLock>
        <Box sx={{ padding: '32px 24px' }}>
          <PaymentFailureScreen
            onSuccessButtonClick={closeDialog}
            orderId={orderId || ''}
            resourcePath={resourcePath || ''}
          />
        </Box>
      </Dialog>
    </Box>
  );
};

export default MyWallet;
