import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography, Box, Link } from '@mui/material';

import COLORS from '../constants/colors';
import MButton from './MButton';

interface PaymentSuccessfulScreenProps {
  onSuccessButtonClick: () => void;
  orderId?: string;
  resourcePath?: string;
  last4Digits?: string;
}

const englishToArabicNumber = (englishNumber: string): string =>
  englishNumber.replace(/[0-9]/g, (c) => '٠١٢٣٤٥٦٧٨٩'.charAt(Number(c)));

const extractPaymentCheckoutId = (resourcePath: string): string => {
  const keyword = resourcePath.includes('/payment') ? '/payment' : '/registration';
  return resourcePath.split(keyword)[0].split('/').pop() || '';
};

const PaymentSuccessfulScreen = ({
  onSuccessButtonClick,
  orderId,
  resourcePath,
  last4Digits,
}: PaymentSuccessfulScreenProps) => {
  const { t, i18n } = useTranslation();
  const isEnglish = i18n.language === 'en';
  const storedAmount = sessionStorage.getItem('lastPaymentAmount');
  const formattedOrderId = orderId?.slice(0, 32) || '';
  const isRecurring = resourcePath?.includes('payment');
  const isCardRegistration = resourcePath?.includes('registration');

  const renderSuccessfulTitle = () => {
    if (isRecurring) {
      return t('recurringPaymentSuccessfulScreen.title');
    }
    if (isCardRegistration) {
      return t('cardRegistrationSuccessfulScreen.title');
    }
    return t('paymentSuccessfulScreen.title');
  };

  const renderSuccessfulDescription = () => {
    if (isRecurring) {
      return t('recurringPaymentSuccessfulScreen.description', {
        amount: isEnglish ? `${storedAmount} SAR` : `${englishToArabicNumber(`${storedAmount}`)} ريال`,
      });
    }
    if (isCardRegistration) {
      return t('cardRegistrationSuccessfulScreen.description', {
        last4Digits: isEnglish ? `${last4Digits}` : `${englishToArabicNumber(`${last4Digits}`)}`,
      });
    }
    return t('paymentSuccessfulScreen.description', {
      amount: isEnglish ? `${storedAmount} SAR` : `${englishToArabicNumber(`${storedAmount}`)} ريال`,
    });
  };

  return (
    <>
      <Box
        sx={{
          padding: { xs: '12px', md: '48px' },
          maxWidth: { xs: '100%', md: '660px' },
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        <img src="/images/icons/mail-sent.svg" alt="mail sent" />
        <Typography
          color={COLORS.SECONDARY_GREEN}
          paragraph
          textAlign="center"
          sx={{ marginTop: 8, marginBottom: 8, fontSize: '16px' }}
        >
          {renderSuccessfulTitle()}
        </Typography>
        <Typography color={COLORS.DARK_GREY} paragraph textAlign="center" sx={{ marginBottom: 0, fontSize: '16px' }}>
          {renderSuccessfulDescription()}
        </Typography>
        {orderId && (
          <Box
            sx={{
              padding: { xs: '12px', md: '48px' },
              maxWidth: { xs: '100%', md: '660px' },
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column',
            }}
          >
            <Box>
              <Typography
                variant={isEnglish ? 'h6' : 'cairoButtonR'}
                color={COLORS.DARK_GREY}
                paragraph
                textAlign="center"
                sx={{ mt: 1, mb: 0, fontSize: '14px' }}
              >
                {t('paymentSuccessfulScreen.orderId')}
              </Typography>
            </Box>
            <Typography
              variant={isEnglish ? 'h6' : 'cairoButtonR'}
              color={COLORS.DARK_GREY}
              paragraph
              textAlign="center"
              sx={{
                marginBottom: 0,
                whiteSpace: { xs: 'normal', sm: 'nowrap' },
                wordBreak: { xs: 'break-word', sm: 'normal' },
                maxWidth: { xs: '150px', sm: 'none' },
                overflow: { sm: 'hidden' },
                textOverflow: { sm: 'ellipsis' },
              }}
            >
              {formattedOrderId}
            </Typography>
            <Box>
              <Link
                component="button"
                color="inherit"
                variant="body2"
                onClick={() => {
                  navigator.clipboard.writeText(formattedOrderId);
                }}
                sx={{ ml: 'auto', fontSize: '14px' }}
              >
                {t('dashboard.congratulation.dialog.copy')}
              </Link>
            </Box>
          </Box>
        )}
        {resourcePath && (
          <Box
            sx={{
              display: 'flex',
              width: { xs: '50%', md: '35%' },
              m: 'auto',
              mt: 8,
              mb: 0,
            }}
          >
            <Typography
              variant={isEnglish ? 'h6' : 'cairoButtonR'}
              color={COLORS.DARK_GREY}
              paragraph
              textAlign="center"
              sx={{ mt: 1, mb: 0, fontSize: '14px' }}
            >
              {t('recurringPaymentSuccessfulScreen.checkoutId')}
            </Typography>
            <Link
              component="button"
              color="inherit"
              variant="body2"
              onClick={() => {
                navigator.clipboard.writeText(extractPaymentCheckoutId(resourcePath));
              }}
              sx={{ ml: 'auto', fontSize: '14px' }}
            >
              {t('dashboard.congratulation.dialog.copy')}
            </Link>
          </Box>
        )}
      </Box>
      <Box
        component="div"
        sx={{
          maxWidth: { xs: '100%', md: '650px' },
          margin: 'auto',
          mx: { xs: '24px', md: 'auto' },
        }}
      >
        <MButton
          id="RequestInvestSuccessDoneButton"
          variant="text"
          onClick={onSuccessButtonClick}
          fullWidth
          buttonProps={{
            sx: {
              height: { xs: '50px', md: 60 },
            },
          }}
        >
          {t('paymentSuccessfulScreen.done')}
        </MButton>
      </Box>
    </>
  );
};

export default PaymentSuccessfulScreen;
