import React, { useCallback, useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';
import LogoutIcon from '@mui/icons-material/Logout';
import Box from '@mui/material/Box';
import DonutSmallOutlinedIcon from '@mui/icons-material/DonutSmallOutlined';
import TrendingUpOutlinedIcon from '@mui/icons-material/TrendingUpOutlined';
import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined';
import ReceiptLongOutlinedIcon from '@mui/icons-material/ReceiptLongOutlined';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import SmartDisplayOutlinedIcon from '@mui/icons-material/SmartDisplayOutlined';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import ModeCommentOutlinedIcon from '@mui/icons-material/ModeCommentOutlined';
import ListAltOutlinedIcon from '@mui/icons-material/ListAltOutlined';
import ShareOutlinedIcon from '@mui/icons-material/ShareOutlined';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import CssBaseline from '@mui/material/CssBaseline';
import { useTheme } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';
import { Autologin } from '../../components/User/Autologin';
import { useLogoutMutation } from '../../redux/features/auth/auth.apiSlice';
import { logOut } from '../../redux/features/auth/auth.slice';
import { locations } from '../../routes/locations';
import MobileNavbar from './Navbar/MobileNavbar';
import Navbar from './Navbar';
import Sidebar from './Sidebar';
import { DrawerHeader } from './Sidebar/SidebarElements';
import { MenuItems } from './Sidebar/types';
import Footer from '../../shared/Footer';
import { getMenuOpen, setMenuOpen } from '../../helpers/LocalStorage';
import { getSurveyResult } from '../../store_deprecated/selectors/surveySelectors';
import { AppDispatch, resetReduxStore } from '../../redux/store';

export default function AppPageLayout() {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const matchMdAndAboveView = useMediaQuery('(min-width:900px)');
  // const { pathname } = useLocation();
  const [open, setOpen] = useState(getMenuOpen() || false);

  const surveyResult = useSelector(getSurveyResult);
  const surveyPassed = Boolean(surveyResult?.category?.id);

  const [logout] = useLogoutMutation();

  const handleDrawerOpen = useCallback(() => {
    setMenuOpen(true);
    setOpen(true);
  }, []);

  const handleDrawerClose = useCallback(() => {
    setMenuOpen(false);
    setOpen(false);
  }, []);

  const handleLogout = useCallback(async () => {
    await logout({});
    dispatch(resetReduxStore());
    navigate(locations.login(), { replace: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /* eslint-disable react/no-unstable-nested-components */
  const prepareMenuItems = () => [
    [
      {
        title: 'menu.dashboard',
        IconComponent: () => <DonutSmallOutlinedIcon />,
        path: locations.dashboard(),
      },
      ...(surveyPassed
        ? [
            {
              title: 'menu.invest',
              IconComponent: () => <TrendingUpOutlinedIcon />,
              path: locations.invest(),
            },
          ]
        : []),
      // TODO: Not included to MVP
      // {
      //   title: "menu.myGoals",
      //   IconComponent: () => <TrackChangesIcon />,
      //   path: locations.myGoals(),
      // },
      {
        title: 'menu.myWallet',
        IconComponent: () => <AccountBalanceWalletOutlinedIcon />,
        path: locations.myWallet(),
      },
      {
        title: 'menu.transactions',
        IconComponent: () => <ReceiptLongOutlinedIcon />,
        path: locations.transactionsHistory(),
      },
      {
        title: 'menu.orders',
        IconComponent: () => <ListAltOutlinedIcon />,
        path: locations.orders(),
      },
      {
        title: 'menu.reports',
        IconComponent: () => <DescriptionOutlinedIcon />,
        path: locations.reports(),
      },
      {
        title: 'menu.tutorials',
        IconComponent: () => <SmartDisplayOutlinedIcon />,
        path: locations.tutorials(),
      },
      {
        title: 'menu.FAQs',
        IconComponent: () => <HelpOutlineOutlinedIcon />,
        path: locations.faqs(),
      },
      {
        title: 'menu.askForAnAdvise',
        IconComponent: () => <ModeCommentOutlinedIcon />,
        path: locations.askForAnAdvise(),
      },
      {
        title: 'menu.invite',
        IconComponent: () => <ShareOutlinedIcon />,
        path: locations.inviteFriend(),
      },
      {
        title: 'menu.about',
        IconComponent: () => <ErrorOutlineOutlinedIcon />,
        path: locations.about(),
      },
    ],
    [
      {
        title: 'menu.logout',
        IconComponent: () => <LogoutIcon sx={{ transform: 'rotate(180deg)' }} />,
        onClick: () => handleLogout(),
      },
    ],
  ];
  /* eslint-enable react/no-unstable-nested-components */

  const [menuItems, setMenuItems] = useState<MenuItems>(prepareMenuItems() || []);

  useEffect(() => {
    setMenuItems(prepareMenuItems());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [surveyPassed]);

  return (
    <>
      {!matchMdAndAboveView && <MobileNavbar handleLogout={handleLogout} menuItems={menuItems} theme={theme} />}
      <Autologin>
        <Box sx={{ display: 'flex' }}>
          <CssBaseline />
          {matchMdAndAboveView && (
            <>
              <Navbar
                open={open}
                handleDrawerOpen={handleDrawerOpen}
                handleDrawerClose={handleDrawerClose}
                handleLogout={handleLogout}
              />
              <Sidebar menuItems={menuItems} open={open} handleDrawerClose={handleDrawerClose} />
            </>
          )}
          <Box
            component="main"
            sx={{
              flexGrow: 1,
              m: 0,
              p: 0,
              pt: 2,
              maxWidth: '100%',
              minHeight: '100vh',
              overflow: 'hidden',
              background: '#F7F9FA',
            }}
          >
            <Box
              sx={{
                '@media (max-width:900px)': {
                  display: 'none',
                },
              }}
            >
              <DrawerHeader />
            </Box>
            <Outlet />
            <Footer />
          </Box>
        </Box>
      </Autologin>
    </>
  );
}
