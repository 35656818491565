import React from 'react';
import { useTranslation } from 'react-i18next';
import Dialog from '@mui/material/Dialog';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import COLORS from '../../../constants/colors';
import MButton from '../../../shared/MButton';

interface ChangeEmailAndMobileSucessModalProps {
  openSuccessfulDialog: boolean;
  onSuccessButtonClick: () => void;
  isPhone: boolean
}

const ChangeEmailAndMobileSucessModal = ({
  openSuccessfulDialog,
  onSuccessButtonClick,
  isPhone
}: ChangeEmailAndMobileSucessModalProps) => {
  const { t, i18n } = useTranslation();
  const isEnglish = i18n.language === 'en';

  return (
    <Dialog
      PaperProps={{
        sx: {
          backgroundColor: 'transparent',
          boxShadow: 'none',
        }
      }}
      open={openSuccessfulDialog}
      onClose={onSuccessButtonClick}
      disableScrollLock
    >
      <Box
        sx={{
          padding: { xs: '24px 24px 0', md: '48px 48px 0' },
          maxWidth: { xs: '100%', md: '650px' },
          height: { xs: '180px', md: '250px' },
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          backgroundColor: 'white'
        }}
      >
        <img src="/images/icons/mail-sent.svg" alt="mail sent" />
        <Typography
          variant={isEnglish ? 'h6' : 'cairoButtonR'}
          color={COLORS.BLACK}
          paragraph
          textAlign="center"
          sx={{ marginTop: { xs: 2, md: 8 }, marginBottom: { xs: 2, md: 8 } }}
        >
          {isPhone ? t('weHaveSuccessfullyUpdatedYourMobile') : t('weHaveSuccessfullyUpdatedYourEmail')}
        </Typography>
      </Box>
      <Box
        component="div"
        sx={{
          maxWidth: { xs: '100%', md: '650px' },
          margin: 'auto',
          mb: '12px',
          mx: { xs: '24px', md: 'auto' },
        }}
      >
        <MButton
          id="RequestInvestSuccessDoneButton"
          variant="text"
          onClick={onSuccessButtonClick}
          fullWidth
          buttonProps={{
            sx: {
              height: { xs: '50px', md: 60 },
            },
          }}
        >
          {t('goToDashboard')}
        </MButton>
      </Box>

    </Dialog>
  );
};

export default ChangeEmailAndMobileSucessModal;
