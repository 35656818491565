import React from 'react';
import { useTranslation } from 'react-i18next';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import MenuIcon from '@mui/icons-material/Menu';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { authSelector } from '../../../redux/features/auth/auth.selectors';
import { useAppDispatch } from '../../../redux/hooks';
import { saveUserPreference } from '../../../redux/modules/userPreference/userPreference.actions';
import { UserPreferenceTypeEnum } from '../../../types/userPreference';
import { AppBar } from './NavbarElements';
import COLORS from '../../../constants/colors';
import { locations } from '../../../routes/locations';

interface INavbarProps {
  open: boolean;
  handleDrawerOpen: () => void;
  handleDrawerClose: () => void;
  handleLogout: () => void;
}

function Navbar(props: INavbarProps) {
  const { open, handleDrawerOpen, handleDrawerClose, handleLogout } = props;
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { user, isLoggedIn } = useSelector(authSelector);

  const languages = { ar: 'العربية', en: 'English' };
  const language = i18n.language === 'ar' ? languages.en : languages.ar;
  const isArabic = i18n.language === 'ar';

  const isMenuOpen = Boolean(anchorEl);
  const menuId = 'primary-account-menu';

  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleChangeLanguage = () => {
    localStorage.setItem('i18nextLng', i18n.language === 'en' ? 'ar' : 'en');
    i18n.changeLanguage(i18n.language === 'en' ? 'ar' : 'en');

    if (isLoggedIn) {
      dispatch(
        saveUserPreference({
          type: UserPreferenceTypeEnum.PERF_LANG,
          value: i18n.language,
        }),
      );
    }
  };

  const handleProfileClick = () => {
    handleMenuClose();
    navigate(locations.myProfile());
  };

  const renderMenu = (
    <Menu
      disableScrollLock
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleProfileClick}>{t('menu.profile')}</MenuItem>
      <MenuItem onClick={handleLogout}>{t('menu.logout')}</MenuItem>
    </Menu>
  );

  return (
    <>
      <AppBar position="fixed" open={open} elevation={0}>
        <Toolbar
          sx={{
            padding: '28px 32px!important',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
          component={Grid}
        >
          <Grid item display="flex" justifyContent="center" alignItems="center">
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={open ? handleDrawerClose : handleDrawerOpen}
              sx={open ? { ml: 0 } : { ml: '96px' }}
              edge="start"
            >
              <MenuIcon sx={{ color: COLORS.MAIN_DARK, fontSize: '24px' }} />
            </IconButton>
            <Typography variant="h6" noWrap component="div" />
          </Grid>
          <Grid item sx={{ flexGrow: 1 }} />
          <Grid container justifyContent="end" alignItems="center" columnSpacing="33px">
            <Grid item>
              <Button
                onClick={handleChangeLanguage}
                sx={{
                  '&:hover': {
                    backgroundColor: COLORS.TRANSPARENT,
                  },
                }}
              >
                <Typography variant={isArabic ? 'body1' : 'cairoM'} color="black" sx={{ textTransform: 'capitalize' }}>
                  {language}
                </Typography>
              </Button>
            </Grid>
            {/* <Grid item> */}
            {/*  <IconButton */}
            {/*    size="small" */}
            {/*    edge="end" */}
            {/*    aria-label="settings" */}
            {/*  > */}
            {/*    <NotificationsOutlinedIcon sx={{ color: COLORS.MAIN_DARK, fontSize: "24px" }} /> */}
            {/*  </IconButton> */}
            {/* </Grid> */}
            {/* <Grid item> */}
            {/*  <IconButton */}
            {/*    size="small" */}
            {/*    edge="end" */}
            {/*    aria-label="settings" */}
            {/*  > */}
            {/*    <SettingsOutlinedIcon sx={{ color: COLORS.MAIN_DARK, fontSize: "24px" }} /> */}
            {/*  </IconButton> */}
            {/* </Grid> */}
            <Grid item>
              <IconButton
                size="small"
                edge="end"
                aria-label="account of current user"
                aria-controls={menuId}
                aria-haspopup="true"
                onClick={handleProfileMenuOpen}
                color="inherit"
              >
                <Avatar sx={{ width: 32, height: 32 }}>
                  {user?.fullName
                    .split(' ')
                    .slice(0, 2)
                    .map((i: string) => i.charAt(0))
                    .join('')}
                </Avatar>
              </IconButton>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      {renderMenu}
    </>
  );
}

export default Navbar;
