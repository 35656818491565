import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { Card, CardContent, Typography, Box } from '@mui/material';
import VideoModal from '../../../shared/VideoModal';
import type { Tutorial } from '../../../types/tutorial';
import getYTVideoThumbnail from '../../../utils/ytThumbnail';
import COLORS from '../../../constants/colors';

interface TutorialCardProps {
  tutorial: Tutorial;
  beta?: boolean;
}

const TutorialCard: React.FC<TutorialCardProps> = ({ tutorial, beta }) => {
  const { t, i18n } = useTranslation();
  const [openVideoModal, setOpenVideoModal] = useState(false);

  const isEnglish = i18n.language === 'en';
  const { title, description, url, urlToGetThumbnail, uploadDate } = tutorial;

  const showVideo = (e: any) => {
    e.stopPropagation();
    setOpenVideoModal(true);
  };

  const hideVideo = (e: any) => {
    e.stopPropagation();
    setOpenVideoModal(false);
  };

  return (
    <Card
      sx={{
        width: { xs: '100%', sm: '97%' },
        height: '100%',
        textAlign: 'left',
        paddingTop: '42px',
        borderRadius: '8px',
        backgroundColor: beta ? 'transparent' : 'white',
        padding: { xs: '18px', sm: beta ? 0 : '20px' },
        boxShadow: beta ? 'none' : '0px 1px 4px rgba(0, 0, 0, 0.05)',
        display: { xs: 'block', sm: 'flex' },
        cursor: 'pointer',
      }}
      elevation={0}
      onClick={showVideo}
    >
      <Box
        sx={{
          width: { xs: '100%', sm: 170 },
          height: { xs: '25vh', sm: 160 },
          my: 'auto',
          mr: { xs: 0, sm: '12px' },
        }}
      >
        <img
          src={getYTVideoThumbnail(urlToGetThumbnail)}
          alt="id"
          width="100%"
          height="100%"
          style={{ borderRadius: '8px', objectFit: 'cover' }}
        />
      </Box>

      <CardContent
        sx={{
          width: { xs: '100%', sm: 'calc(100% - 170px)' },
          my: { xs: '10px', sm: 'auto' },
          p: '0 !important',
        }}
      >
        <Typography
          variant={isEnglish ? 'h5' : 'cairoButton'}
          sx={{ fontSize: beta ? 20 : 'clamp(1rem,0.5vw + 1rem,1.5rem)', fontWeight: beta ? 400 : 500 }}
          color={COLORS.MAIN_DARK}
          component="div"
        >
          {title}
        </Typography>
        <Typography variant="bodySmall" color={COLORS.DARK_GREY} component="div" sx={{ marginTop: '8px' }}>
          {description}
        </Typography>
        <Typography
          variant="bodySmall"
          color={COLORS.LIGHT_GRAY}
          component="div"
          sx={{ marginTop: '8px', fontSize: beta ? 12 : 14, fontWeight: beta ? 600 : 400 }}
        >
          {`${t('tutorials.uploaded')} ${moment(uploadDate, 'DD/MM/YYYY').locale(i18n.language).format('MMM D YYYY')}`}
        </Typography>
      </CardContent>
      <VideoModal open={openVideoModal} title={title} videoUrl={url} handleClose={hideVideo} />
    </Card>
  );
};

export default TutorialCard;
