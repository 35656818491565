import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useSelector } from 'react-redux';
import { authSelector } from '../../../redux/features/auth/auth.selectors';
import SendingEmailScreens from './SendingEmailScreens';
import { contactUs } from '../../../helpers/api_deprecated';
import { isTextValid, isEmailValid } from '../../../helpers/string';
import COLORS from '../../../constants/colors';
import MButton from '../../../shared/MButton';

const fullNameLength = 100;
const emailLength = 100;
const messageLength = 500;

interface ContactFormProps {
  showEmailUsPopup: boolean;
  handleShowEmailUsPopup: () => void;
}

const ContactForm: React.FC<ContactFormProps> = ({ showEmailUsPopup, handleShowEmailUsPopup }) => {
  const { t, i18n } = useTranslation('');
  const isEnglish = i18n.language === 'en';
  const { user } = useSelector(authSelector);

  const [fullName, setFullName] = useState(user?.fullName || '');
  const [email, setEmail] = useState(user?.email || '');
  const [message, setMessage] = useState('');
  const [isFullNameInValidFormat, setIsFullNameInValidFormat] = useState('');
  const [isEmailInValidFormat, setIsEmailInValidFormat] = useState('');
  const [isMessageInValidFormat, setIsMessageInValidFormat] = useState('');

  const [isLoading, setIsLoading] = useState(false);
  const [isErrorExist, setIsErrorExist] = useState(false);
  const [successSubmission, setSuccessSubmission] = useState(false);

  const handleChangeFullName = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setIsFullNameInValidFormat('');
    const fName = e.target.value;
    if (fName.length > fullNameLength) return;

    setFullName(fName);
  };

  const handleChangeEmail = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setIsEmailInValidFormat('');
    const mail = e.target.value;
    if (mail.length > emailLength) return;

    setEmail(mail);
  };

  const handleChangeMessage = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setIsMessageInValidFormat('');
    const msg = e.target.value;
    if (msg.length > messageLength) return;

    setMessage(msg);
  };

  const clearFields = (isUserExists = false) => {
    if (!isUserExists) {
      setFullName('');
      setEmail('');
    }
    setMessage('');
    setIsFullNameInValidFormat('');
    setIsMessageInValidFormat('');
    setIsEmailInValidFormat('');
  };

  const closePopupAfter = (time = 3) => {
    setTimeout(() => {
      handleShowEmailUsPopup();
    }, time * 1000);
  };

  const handleOnClickSend = async () => {
    let fullNameValidationStatus = '';
    let messageValidationStatus = '';
    let emailValidationStatus = '';

    if (fullName === '') fullNameValidationStatus = 'requiredField';
    else if (!isTextValid(fullName)) fullNameValidationStatus = 'invalidFormat';

    if (message === '') messageValidationStatus = 'requiredField';
    else if (!isTextValid(message)) messageValidationStatus = 'invalidFormat';

    if (email === '') emailValidationStatus = 'requiredField';
    else if (!isEmailValid(email)) emailValidationStatus = 'invalidFormat';

    setIsFullNameInValidFormat(fullNameValidationStatus);
    setIsMessageInValidFormat(messageValidationStatus);
    setIsEmailInValidFormat(emailValidationStatus);

    if (fullNameValidationStatus || messageValidationStatus || emailValidationStatus) return;

    const payload = {
      from: email.trim(),
      name: fullName.trim(),
      body: message.trim(),
    };

    try {
      setIsLoading(true);
      await contactUs(payload);
      setIsLoading(false);
      setIsErrorExist(false);
      setSuccessSubmission(true);
      const isUserExists = user?.fullName !== '' && user?.email !== '';
      clearFields(isUserExists);
      closePopupAfter();
    } catch (error) {
      setIsLoading(false);
      setIsErrorExist(true);
      setSuccessSubmission(false);
      closePopupAfter();
    }
  };

  useEffect(() => {
    const isUserExists = user?.fullName !== '' && user?.email !== '';
    clearFields(isUserExists);
    if (showEmailUsPopup) {
      setIsLoading(false);
      setIsErrorExist(false);
      setSuccessSubmission(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showEmailUsPopup, user]);

  if (!isLoading && !successSubmission && !isErrorExist) {
    return (
      <Grid item xs={12} sm={12}>
        <Typography
          variant={isEnglish ? 'h4' : 'h3'}
          color={COLORS.MAIN_DARK}
          paragraph
          marginBottom={3}
          align="center"
        >
          {t('contactUs.popupFormTitle')}
        </Typography>
        <Typography variant="bodySmall" color={COLORS.MAIN_DARK} paragraph marginBottom={12} align="center">
          {t('contactUs.contactFormSubtitle')}
        </Typography>
        <FormControl sx={{ width: '100%' }}>
          <Box marginBottom={8}>
            <Typography
              variant={isEnglish ? 'bodyMedium' : 'cairoM'}
              color={COLORS.MAIN_DARK}
              textAlign="justify"
              paragraph
              sx={{ marginBottom: 1 }}
            >
              {t('contactUs.contactFormFullNameLabel')}
            </Typography>
            <TextField
              id="ContactFormFullName"
              variant="outlined"
              fullWidth
              value={fullName}
              sx={{
                borderRadius: '8px',
                '& .MuiFormHelperText-root': {
                  fontFamily: isEnglish ? 'Montserrat' : 'Noto Kufi Arabic',
                },
                '& .MuiFormLabel-root.Mui-error': {
                  backgroundColor: 'transparent !important',
                },
                '& .MuiInput-underline.Mui-error:after': {
                  borderBottomColor: 'transparent !important',
                },
                '& .MuiFormHelperText-root.Mui-error': {
                  backgroundColor: 'transparent !important',
                },
              }}
              InputProps={{
                style: {
                  fontFamily: isEnglish ? 'Montserrat' : 'Noto Kufi Arabic',
                  backgroundColor: 'white',
                },
              }}
              placeholder={t('contactUs.contactFormFullNamePlaceholder')}
              type="text"
              onChange={handleChangeFullName}
              error={isFullNameInValidFormat !== ''}
              helperText={isFullNameInValidFormat && t(`contactUs.${isFullNameInValidFormat}`)}
              disabled={isLoading}
            />
          </Box>
        </FormControl>
        <FormControl sx={{ width: '100%' }}>
          <Box marginBottom={8}>
            <Typography
              variant={isEnglish ? 'bodyMedium' : 'cairoM'}
              color={COLORS.MAIN_DARK}
              textAlign="justify"
              paragraph
              sx={{ marginBottom: 1 }}
            >
              {t('contactUs.contactFormEmailLabel')}
            </Typography>
            <TextField
              id="ContactFormEmail"
              variant="outlined"
              fullWidth
              value={email}
              sx={{
                borderRadius: '8px',
                '& .MuiFormHelperText-root': {
                  fontFamily: isEnglish ? 'Montserrat' : 'Noto Kufi Arabic',
                },
                '& .MuiFormLabel-root.Mui-error': {
                  backgroundColor: 'transparent !important',
                },
                '& .MuiInput-underline.Mui-error:after': {
                  borderBottomColor: 'transparent !important',
                },
                '& .MuiFormHelperText-root.Mui-error': {
                  backgroundColor: 'transparent !important',
                },
              }}
              InputProps={{
                style: {
                  fontFamily: isEnglish ? 'Montserrat' : 'Noto Kufi Arabic',
                  backgroundColor: 'white',
                },
              }}
              placeholder={t('contactUs.contactFormEmailPlaceholder')}
              type="text"
              onChange={handleChangeEmail}
              error={isEmailInValidFormat !== ''}
              helperText={isEmailInValidFormat && t(`contactUs.${isEmailInValidFormat}`)}
              disabled={isLoading}
            />
          </Box>
        </FormControl>
        <FormControl sx={{ width: '100%' }}>
          <Box marginBottom={8}>
            <Typography
              variant={isEnglish ? 'bodyMedium' : 'cairoM'}
              color={COLORS.MAIN_DARK}
              textAlign="justify"
              paragraph
              sx={{ marginBottom: 1 }}
            >
              {t('contactUs.contactFormMessageLabel')}
            </Typography>
            <TextField
              id="ContactFormMessage"
              variant="outlined"
              fullWidth
              value={message}
              placeholder={t('contactUs.contactFormMessagePlaceholder')}
              onChange={handleChangeMessage}
              error={isMessageInValidFormat !== ''}
              helperText={isMessageInValidFormat && t(`contactUs.${isMessageInValidFormat}`)}
              sx={{
                borderRadius: '8px',
                '& .MuiFormHelperText-root': {
                  fontFamily: isEnglish ? 'Montserrat' : 'Noto Kufi Arabic',
                },
                '& .MuiFormLabel-root.Mui-error': {
                  backgroundColor: 'transparent !important',
                },
                '& .MuiInput-underline.Mui-error:after': {
                  borderBottomColor: 'transparent !important',
                },
                '& .MuiFormHelperText-root.Mui-error': {
                  backgroundColor: 'transparent !important',
                },
              }}
              InputProps={{
                style: {
                  fontFamily: isEnglish ? 'Montserrat' : 'Noto Kufi Arabic',
                  opacity: '0.55 !important',
                  fontWeight: 400,
                  backgroundColor: 'white',
                },
              }}
              style={{
                resize: 'none',
                fontSize: '1rem',
                borderRadius: '8px',
                // @ts-ignore
                '&:focus': {
                  borderColor: '#2482be',
                  borderWidth: '2px',
                  outlineWidth: '0 ',
                },
              }}
              multiline
              rows={4}
              disabled={isLoading}
            />
          </Box>
        </FormControl>
        <Box display="flex" justify-content="space-between" flex-direction="row" gap="20px">
          <MButton
            id="ContactFormSendEmailButton"
            variant="contained"
            onClick={handleOnClickSend}
            disabled={isLoading}
            buttonProps={{
              size: 'medium',
              sx: {
                minWidth: 'auto',
              },
            }}
          >
            <Typography
              variant={isEnglish ? 'bodyMediumB' : 'bodyMediumB'}
              textAlign="center"
              color="white"
              sx={{ textTransform: 'initial' }}
            >
              {t('contactUs.contactFormSendMailButton')}
            </Typography>
          </MButton>
          <MButton
            id="ContactFormCancelButton"
            variant="outlined"
            onClick={handleShowEmailUsPopup}
            disabled={isLoading}
            buttonProps={{
              size: 'medium',
              sx: {
                minWidth: 'auto',
              },
            }}
          >
            <Typography
              variant={isEnglish ? 'bodyMediumB' : 'bodyMediumB'}
              textAlign="center"
              sx={{ textTransform: 'initial' }}
            >
              {t('contactUs.contactFormCancelButton')}
            </Typography>
          </MButton>
        </Box>
      </Grid>
    );
  }

  return (
    <Grid
      item
      xs={12}
      sm={6}
      sx={{
        height: { xs: '375px', sm: '375px' },
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <SendingEmailScreens isLoading={isLoading} isErrorExist={isErrorExist} successSubmission={successSubmission} />
    </Grid>
  );
};

export default ContactForm;
