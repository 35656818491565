import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import {
  useFindProfileDataQuery,
} from '../../../redux/features/auth/auth.apiSlice';
import { ReduxState, useTypedDispatch } from '../../../redux/store';
import { fetchLastSurveyResult } from '../../../store_deprecated/actions/surveyActions';
import { findProfileData } from '../../../store_deprecated/actions/authActions';
import SURVEY_STATUS from '../../../constants/SurveyStatus';
import CenterContent from '../../../shared/CenterContent';
import { locations } from '../../../routes/locations';
import COLORS from '../../../constants/colors';
import MButton from '../../../shared/MButton';
import Loader from '../../../shared/Loader';
import { fetchUserPreferenceByType } from '../../../redux/modules/userPreference/userPreference.actions';
import { UserPreferenceTypeEnum } from '../../../types/userPreference';
import { userPreferenceByTypeSelector } from '../../../redux/modules/userPreference/userPreference.selectors';
import { fetchStatuses } from '../../../constants/fetchStatuses';
import { KycStatus } from '../../../types/kyc';

interface HomeViewProps {
  surveyStatus: string;
}

const HomeView: React.FC<HomeViewProps> = ({ surveyStatus }) => {
  const { t, i18n } = useTranslation();
  const isEnglish = i18n.language === 'en';
  const navigate = useNavigate();
  const dispatch = useTypedDispatch();
  const [loading, setLoading] = useState(false);
  const [isDataFetched, setIsDataFetched] = useState(false);
  const { data, isLoading: profileDataLoading } = useFindProfileDataQuery();

  const { userPreference: productTypePreference, fetchStatus: userPreferenceFetchStatus } = useSelector(
    (state: ReduxState) => userPreferenceByTypeSelector(state, UserPreferenceTypeEnum.PRODUCT_TYPE),
  );

  useEffect(() => {
    if (!data?.id || !data?.confirmDate) {
      return;
    }

    if ([SURVEY_STATUS.NEW, SURVEY_STATUS.PENDING].includes(surveyStatus)) {
      navigate(locations.survey());
    } else {
      navigate(locations.dashboard());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [surveyStatus, data]);

  useEffect(() => {
    if (userPreferenceFetchStatus === fetchStatuses.pending) {
      return;
    }

    if (!productTypePreference && userPreferenceFetchStatus === fetchStatuses.idle) {
      dispatch(fetchUserPreferenceByType(UserPreferenceTypeEnum.PRODUCT_TYPE));
    }

    if (!productTypePreference && userPreferenceFetchStatus === fetchStatuses.success) {
      navigate(locations.products());
    }
  }, [productTypePreference, userPreferenceFetchStatus]);

  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        await Promise.all([
          dispatch(fetchLastSurveyResult()),
          dispatch(findProfileData()),
          dispatch(fetchUserPreferenceByType(UserPreferenceTypeEnum.PRODUCT_TYPE)),
        ]).then((e) => {
          setIsDataFetched(true);
        });
      } catch (e) {
        console.error('Some error');
      }
    };

    fetchInitialData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setLoading(true);
    if (!isDataFetched) return;

    if (
      ![SURVEY_STATUS.NEW, SURVEY_STATUS.PENDING, SURVEY_STATUS.COMPLETED].includes(surveyStatus) &&
      !(!productTypePreference && userPreferenceFetchStatus === fetchStatuses.success)
    ) {
      setLoading(false);
    }

    if (data?.id && !data?.confirmDate) {
      return navigate(locations?.nafazData());
    }
    if (data?.id) {
      if (
        [KycStatus.ACTIVE, KycStatus.PENDING].includes(data?.status?.name) &&
        [SURVEY_STATUS.NEW, SURVEY_STATUS.PENDING].includes(surveyStatus)
      ) {
        return navigate(locations.survey());
      }
      if (
        [KycStatus.ACTIVE, KycStatus.PENDING].includes(data?.status?.name) &&
        [SURVEY_STATUS.COMPLETED].includes(surveyStatus)
      ) {
        return navigate(locations.dashboard());
      }
    } else {
      setLoading(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [surveyStatus, data, isDataFetched]);

  const onContinue = async () => {
    try {
      if (!data?.id) {
        return navigate(locations.nafazAuthentication());
        // const isEnabled = await auth.runIamAuthorization();

        // if (isEnabled) {
        //   return;
        // }

        // return alert(
        //   "Nafaz is disabled! Please update the KYC data in admin and change the user's status",
        // );
      }

      if (data?.id && !data?.confirmDate) {
        return navigate(locations.nafazData());
      }

      if ([SURVEY_STATUS.COMPLETED].includes(surveyStatus)) {
        return navigate(locations.dashboard());
      }

      return navigate(locations.survey());
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  return loading ? (
    <Loader />
  ) : (
    <CenterContent>
      <Box
        sx={{
          position: 'absolute',
          left: 0,
          bottom: '1.7vh',
          display: { md: 'block', sm: 'none', xs: 'none' },
        }}
      >
        <img
          style={{ width: '100%' }}
          src={isEnglish ? '/images/logo-left-cut.svg' : '/images/logo-right-cut.svg'}
          alt=""
        />
      </Box>
      <Box
        sx={{
          marginX: { xs: '24px', md: '112px' },
          marginY: { xs: '24px', md: '40px' },
          width: '100%',
          padding: { xs: '24px', md: '48px' },
          height: '100%',
          background: COLORS.WHITE,
          borderRadius: { xs: '12px', md: '24px' },
          zIndex: 2,
        }}
      >
        <Grid rowSpacing="32px" container flexDirection="column" justifyContent="center" alignItems="center">
          <Grid
            item
            sx={{
              width: { xs: '100%', md: '300px' },
              height: { xs: 'auto', md: '300px' },
            }}
          >
            <img src="/images/welcome.png" alt="" style={{ width: '100%', paddingTop: '24px' }} />
          </Grid>
          <Grid item>
            <Typography component="h1" align="center" variant={isEnglish ? 'smallDisplayH' : 'cairoHeader1B'}>
              {t('home.title')}
            </Typography>
          </Grid>
          <Grid item textAlign="center">
            <Typography variant={isEnglish ? 'bodyLarge' : 'cairoBody'} sx={{ color: COLORS.LIGHT_GRAY }}>
              {t('home.description')}
            </Typography>
          </Grid>

          <Grid item sx={{ width: '100%' }} maxWidth="sm">
            <MButton
              fullWidth
              variant="contained"
              onClick={onContinue}
              isLoading={loading || profileDataLoading}
              id="ContinueButton"
              buttonProps={{
                sx: {
                  width: { xs: '100%', md: '100%' },
                  height: { xs: '50px', md: 60 },
                  borderRadius: '80px',
                  boxShadow: 'none',
                  backgroundColor: COLORS?.X_DARK_BLUE,
                },
              }}
            >
              {t('continue')}
            </MButton>
          </Grid>
        </Grid>
      </Box>
    </CenterContent>
  );
};

export default HomeView;
